export function generate24HoursArray() {
	const arr = Array.from({
		length: 24 * 60 / 5
	}, (v, i) => {
		let h = Math.floor(i * 15 / 60);
		let m = i * 15 - h * 60;
		//convert to 12 hours time
		//pad zero to minute
		if (m < 10) {
			m = '0' + m;
		}
		return h + ':' + m;
	});
	return arr;
}