import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { GET } from '../../scripts/api';
import './Page.css';
import TooltipItem from '../tooltip/Tooltip';
import { isAdmin } from '../../scripts/roleHelper';

class Page extends Component {

    state = {
        page: null,
        loading: true,
        error: null
    }

    componentDidMount() {
        this.getPageContent(this.props.location.pathname)
    }

    getPageContent = location => {
        // Get rid of slash before url
        location = location.substr(1);
        GET('page',
            { location },
            page => {
                document.getElementById('page-content').innerHTML = page.content
                this.setState({page})
            },
            error => this.setState({error: true})
        )
    }

    findIdByPageName = () => {
        const { pages } = this.props;
        if(!!pages) {
            const currentPageName = this.props.location.pathname;
            const actualPageName = currentPageName.substr(1);
            return pages.find(page => {
                return page.pageUrl === actualPageName
            }).id
        }
    }



    render() {

        const { page, error } = this.state;
        const { currentUser } = this.props

        return (
            <>
                <div className="content">
                    {currentUser && isAdmin(currentUser) &&
                        <div className="row float-right mr-2">
                            <div className="col-xs-12 mt-3 mx-3">
                                <TooltipItem text="Bewerk pagina">
                                    <Link to={'/admin/pages/edit/' + this.findIdByPageName()} >
                                        <button className="btn btn-sm btn-basic edit-page">
                                            <i className="fa fa-edit"></i>
                                        </button>
                                    </Link>
                                </TooltipItem>
                            </div>
                        </div>}
                    <div className="container-fluid">
                        {!page && !error &&
                        <div className="loading-container">
                            <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                        </div>}
                        {error && <div className="not-found-container">
                            <h2 className="text-secondary font-weight-light"><i className="fa fa-exclamation-triangle"></i><span> Oeps, Pagina niet gevonden.</span></h2>
                        </div>}
                        {/* {page && <>
                            <h1>{page.title}</h1>
                            <br/>
                        </>} */}
                        <span id="page-content">
                        </span>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            currentUser: state.authReducer.currentUser,
            pages: state.pageReducer.pages
        }
    }
) (Page));