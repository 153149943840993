import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css"
import { generate24HoursArray } from '../../../scripts/timeHelper';
import { GET, POST } from '../../../scripts/api';
import Tooltip from '../../tooltip/Tooltip';
import OpeningTimeRow from './OpeningTimeRow';
import EditTimesModal from './EditTimesModal';
import AddTimeModal from './AddTimeModal';
import { connect } from 'react-redux';

let AdminSettings = props => {

    const { openingTimes, dispatch } = props

    const [ editTimesId, setEditTimesId ] = useState(null);

    const [addTimeOpen, setAddTimeOpen] = useState(false)

    const getOpeningTimes = () => {
        GET('times/opening-times', {},
            openingTimes => dispatch({
                type: 'SET_TIMES',
                times: openingTimes
            }),
            error => console.log(error)
        );
    }

    useEffect(() => {
        getOpeningTimes();
    }, [])
    
    const getDefaultDays = () => {
        return openingTimes && openingTimes.filter(time => !!time.defaultDay)
    }

    const getOtherDays = () => {
        return openingTimes && openingTimes.filter(time => !time.defaultDay)
    }

    const remove = id => {
        POST('times/remove-opening-times', {id},
            () => getOpeningTimes(),
            error => console.log(error)
        )
    }

    const defaultDays = getDefaultDays();

    const otherDays = getOtherDays();

    return (
        <div className="content">
            <div className="container-fluid">
                <h3>
                    <i className="fa fa-cog"></i> Overige Instellingen
                    <Link to={'/admin'} ><button type="button" className="float-right btn btn-sm btn-outline-secondary"><i className="fa fa-chevron-left"></i> Terug</button></Link>
                </h3>
                <hr />
                <div className="row ">
                    <div className="col-sm-12 small">
                        <h5>
                            <i className="fa fa-clock"></i> Openingstijden
                            <button type="button" onClick={() => setAddTimeOpen(true)} className="btn btn-outline-primary btn-sm float-right">
                                <i className="fa fa-plus"></i> Extra openingstijd toevoegen
                            </button>
                        </h5>
                        <hr className="mt-4" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        {openingTimes && <ul className="list-group">
                            <h5>Standaard openingstijden</h5>
                            <br/>
                            {getDefaultDays().map(time => <span key={time.id}><OpeningTimeRow remove={id => remove(id)} edit={id => setEditTimesId(id)} time={time} /></span>)}
                            <br />
                            {!!(otherDays && otherDays.length) && <>
                                <h5>Andere openingstijden</h5>
                                <br/>
                                {otherDays.map(time => <span key={time.id}><OpeningTimeRow remove={id => remove(id)} edit={id => setEditTimesId(id)} time={time} /></span>)}
                            </>}
                        </ul>}
                    </div>
                </div>
                {editTimesId && <EditTimesModal reloadDays={() => getOpeningTimes()} time={openingTimes.find(timeItem => timeItem.id === editTimesId)} close={() => setEditTimesId(null)} />}
                {addTimeOpen && <AddTimeModal reloadDays={() => getOpeningTimes()} close={() => setAddTimeOpen(false)} />}
            </div>
        
        </div>
    );
}



export default connect(
    state => {
        return {
            openingTimes: state.pageReducer.openingTimes
        }
    }
)(AdminSettings);