import React, { Component } from 'react';
import { formatDate } from '../../scripts/dateFormatter';
import Tooltip from '../tooltip/Tooltip';

class DateButton extends Component {

    render() {
        const { date } = this.props;

        return (
            <>
                {date && <>
                    <Tooltip text={date}>
                        <button disabled className="btn btn-outline-secondary btn-sm"><i className="fa fa-calendar-alt"></i> {formatDate(date)} </button>
                    </Tooltip>
                </>}
                {!date && <i className="fa fa-times text-danger"></i>}
            </>
        );
    }
}

export default DateButton;