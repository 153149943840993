import React, { Component } from 'react';

class SortButton extends Component {

    direction = {
        DOWN: 'down',
        UP: 'up'
    }

    state = {
        currentDirection: this.direction.DOWN
    }

    sort = sortOption => {
        let direction = this.direction.DOWN
        if(this.state.currentDirection === this.direction.DOWN) {
            direction = this.direction.UP
        }
        this.setState({currentDirection: direction});
        this.props.onSort(sortOption, direction)
    }

    render() {

        const { sortOption } = this.props;
        const { currentDirection } = this.state;

        return (
            <>
                <span onClick={() => this.sort(sortOption)} className="sort-icon">
                    {currentDirection === this.direction.DOWN && <i className="fa-lg fa fa-caret-down"></i>}
                    {currentDirection === this.direction.UP && <i className="fa-lg fa fa-caret-up"></i>}
                </span>
            </>
            
        );
    }
}

export default SortButton;