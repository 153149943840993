import React, { Component } from 'react';
import { GET } from '../../../scripts/api';
import TooltipItem from '../../tooltip/Tooltip';
import { formatDate } from '../../../scripts/dateFormatter';
import PrevPageButton from '../../buttons/PrevPageButton';
import { connect } from 'react-redux';
import DeleteSignupModal from './DeleteSignupModal';
import ExportEmailExcel from './export/ExportEmailExcel';

class MailUserSettings extends Component {

	state = {
		mailingList: null
	}

	componentDidMount() {
		this.getMailingList()
	}

	getMailingList = () => {
		GET('admin/mailing-list',
			{},
			mailingList => this.setState({mailingList}),
			error => console.error(error)
		)
	}

	deleteSignup = id => {
		this.props.dispatch({
			type: 'SET_SIGNUP_TO_DELETE',
			id
		})
	}

	render() {

		const { mailingList } = this.state;

		return (
			<div className="content">
				<div className="container-fluid">
					<div className="row">
                        <div className="col-sm-12 col-md-8 col-lg-8 ">
                            <h3>
								<i className="fa fa-envelope"></i> Nieuwsbrief aanmeldingen ({mailingList ? mailingList.length : 0})
                            </h3>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
							<span className="my-1">
								<PrevPageButton to="/admin" />
							</span>
						</div>	
					</div>					
					<hr />
					{mailingList && <>
						<div className="row">
							<div className="col-12 mb-3">
								<ExportEmailExcel data={mailingList} />
							</div>
						</div>
						<ul className="list-group ">
							{mailingList.map(signup => {
								return (<li key={signup.id} className="list-group-item">
									<div className="row">
										<div className="col-6">
											<strong>
												<span className="badge badge-light px-2 py-1 font-weight-light mx-2">
													@
												</span> 
												<span className="small pt-3">
													<strong>{signup.email}</strong>
												</span>
											</strong>
										</div>
										<div className="col-6">
											<div className="float-right">
												<TooltipItem text={
													<span>{formatDate(signup.createdAt)} aangemeld</span>}>
													<button className="mx-1 btn btn-sm btn-outline-primary">
														<i className="px-1 fa fa-calendar-check"></i>
													</button>
												</TooltipItem>
												<TooltipItem text={
													<span>Aanmelding verwijderen</span>}>
													<button onClick={() => this.deleteSignup(signup.id)} className="mx-1 btn btn-sm btn-outline-danger">
														<i className="px-1 fa fa-times"></i>
													</button>
												</TooltipItem>
											</div>
										</div>
									</div>
									
								</li>)
							})}
						</ul>
					</>}
					{!mailingList && <div className="loading-container">
						<div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
					</div>}
				</div>
				<DeleteSignupModal setMailingList={list => this.setState({mailingList: list})} />
			</div>
		);
	}
}

export default connect(
	state => {
		return {
			signupToDelete: state.pageReducer.signupToDelete
		}
	}
) (MailUserSettings);