export function getNestedChildren(arr, parentId, property) {
	var out = []
	arr.forEach((item, i) => {
		if(arr[i][property] == parentId) {
			var children = getNestedChildren(arr, arr[i].id, property)

			if(children.length) {
				arr[i].children = children
			}
			out.push(arr[i])
		}
	});
	return out
}

export function sortByProperty(property, list) {
    return list.sort((a, b) =>     {
        return a[property] - b[property];
    })
}