import React, { Component } from 'react';
import ReparatieStatusButton from '../buttons/ReparatieStatusButton';
import TicketUserButton from '../buttons/TicketUserButton';
import { GET, DELETE, POST } from '../../scripts/api';
import './Tickets.css';
import SortButton from '../table/SortButton';
import { sortProperties, sortTicketsByProperty } from '../../scripts/sortTickets';
import Filter from '../table/Filter';
import { connect } from 'react-redux'; 
import DateButton from '../buttons/DateButton';
import { Link } from 'react-router-dom';
import DetailsButton from '../buttons/DetailsButton';
import { Modal } from 'react-bootstrap';
import { isAdmin } from '../../scripts/roleHelper';
import Pagination from '../table/Pagination';

class Tickets extends Component {

    state = {
        error: null,
        showDeleted: false,
        currentPage: 1,
        pageSize: 10
    }

    resetFilters = () => {
        this.props.dispatch({
            type: 'CLEAR_FILTER_OPTIONS'
        })
    }

    clearTicketIdToDelete = () => {
        this.props.dispatch({
            type: 'SET_TICKET_TO_DELETE',
            ticketId: null
        })
    }

    toggleFilter = () => {
        this.props.dispatch({type: 'TOGGLE_FILTER'})
    }

    sortByProp = (property, direction) => {
        this.props.dispatch({
            type: 'SET_SORT_OPTION',
            sortOption: property
        })
        this.props.dispatch({
            type: 'SET_SORT_DIRECTION',
            sortDirection: direction
        })
    }

    componentDidMount() {
        this.getTickets({});   
    }

    showDeletedTickets = () => {
        const { showDeleted } = this.state
        this.setState({ 
            showDeleted: !showDeleted, 
            currentPage: 1 
        })
    }

    getTickets = params => {
        GET('tickets', 
            params,
            tickets => this.props.dispatch({
                type: 'SET_TICKETS', 
                tickets
            }),
            () => this.setState({error: true})
        );
    }

    setPageSize = e => {
        this.setState({pageSize: e.target.value})
    }

    deleteTicket = () => {
        const { ticketIdToDelete } = this.props

        POST('tickets/remove/' + ticketIdToDelete,
            {},
            newTickets => {
                this.props.dispatch({
                    type: 'SET_TICKETS',
                    tickets: newTickets
                })
                this.props.dispatch({
                    type: 'SET_TICKET_TO_DELETE',
                    ticketId: null
                })
            },
            error => console.error(error)
        );
    }

    setSearchQuery = e => {
        this.props.dispatch({
            type: 'SET_SEARCH_QUERY',
            searchQuery: e.target.value
        })
    }

    clearSearchQuery = () => {
        this.props.dispatch({
            type: 'SET_SEARCH_QUERY',
            searchQuery: ''
        })
    }

    setPage = pageNumber => {
        this.setState({currentPage: pageNumber})
    }

    filterDeleted = tickets => {
        const { showDeleted } = this.state
        return tickets.filter(ticket => {
            return !!ticket.isDeleted === showDeleted
        })
    }

    filterByOptions = tickets => {
        const { filterOptions } = this.props
        const { author, editor, status } = filterOptions

        return tickets.filter(ticket => {
            return  (!status.length || (ticket.ticket_status && (status.map(status => status.id).indexOf(ticket.ticket_status.id) !== -1))) &&
                    (!author.length || (ticket.user_created && (author.map(author => author.id).indexOf(ticket.user_created.id) !== -1))) &&
                    (!editor.length || (ticket.user_last_edited && (editor.map(editor => editor.id).indexOf(ticket.user_last_edited.id) !== -1)))
            })
    }

    filterBySorting = tickets => {
        const { currentSortDirection, currentSortOption } = this.props;
        return sortTicketsByProperty(currentSortOption, currentSortDirection, tickets);
    }

    filterBySearch = tickets => {
        let { searchQuery } = this.props;
        searchQuery = searchQuery.toLowerCase();

        return tickets.filter(ticket => {
            return  ticket.title.toLowerCase().indexOf(searchQuery) !== -1 ||
                    ticket.content.toLowerCase().indexOf(searchQuery) !== -1 ||
                    `cc-${ticket.id}`.indexOf(searchQuery) !== -1 ||
                    (ticket.user_created && ticket.user_created.name.toLowerCase().indexOf(searchQuery) !== -1) ||
                    (ticket.ticket_status && ticket.ticket_status.name.toLowerCase().indexOf(searchQuery) !== -1) ||
                    (ticket.user_last_edited && ticket.user_last_edited.name.toLowerCase().indexOf(searchQuery) !== -1)
        })
    }

    filterPage = tickets => {
        const { currentPage, pageSize } = this.state;

        return tickets.slice((currentPage -1) * pageSize, Math.min(tickets.length, (currentPage * pageSize)));
    }

    filterAssigned = tickets => {

        const { filterOptions } = this.props;
        const { assigned } = filterOptions;

        const assignedFilterIds = assigned.map(assigned => assigned.id);
        const ticketsWithUsersAssigned = tickets.filter(ticket => ticket.users_assigned && !!ticket.users_assigned.length);

        if(!assignedFilterIds.length) {
            return tickets
        }
        return ticketsWithUsersAssigned.filter(ticket => {
            let shouldShowTicket = false;
            const userCreatedIds = ticket.users_assigned.map(assignment => assignment.user.id);
            userCreatedIds.forEach(userId => {
                if(assignedFilterIds.indexOf(userId) !== -1) {
                    shouldShowTicket = true
                }
            })
            return shouldShowTicket;
          
        }) 
    }

    applyFilterOptions = tickets => {

        var t0 = performance.now();
        tickets = this.filterBySearch(tickets);
        tickets = this.filterBySorting(tickets);
        tickets = this.filterByOptions(tickets);
        tickets = this.filterDeleted(tickets);
        tickets = this.filterAssigned(tickets);
        var t1 = performance.now();
        return tickets;
    }

    render() {

        const { tickets, searchQuery, currentSortOption, filtersToggled, ticketIdToDelete, currentUser } = this.props;
        const { showDeleted, pageSize, currentPage, error } = this.state;
        const filteredTickets = !!tickets ? this.applyFilterOptions(tickets) : [];
        const currentPageTickets = !!filteredTickets ? this.filterPage(filteredTickets) : [];

        const sortProps = sortProperties();

        return (
            <>
                <div className="content">
                    <div className="container-fluid">
                        {tickets && !error && <>
                        {/* Filteren */}
                        <div className="row">
                            {/* Zoeken */}
                            <div className="col-sm-12">
                                <h3><i className="fa fa-inbox"></i> Ticketcentrum ({filteredTickets.length})<Link to="/tickets/create"><button onClick={this.toggleFilter} className="float-right btn btn-sm btn-outline-success"><i className="fa fa-envelope"></i> <i className="fa fa-plus fa-xs fa-add-extend"></i> Ticket Aanmaken</button></Link></h3>
                                <hr />
                            </div>
                            <div className="col-sm-4 pr-1 pt-2 pb-2 pr-3">
                                <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text pl-3 pr-3"><i className="fa fa-search"></i></span>
                                    </div>
                                    <input onChange={this.setSearchQuery} value={searchQuery} type="text" className="form-control" placeholder="Doorzoek tickets" />
                                    <div onClick={this.clearSearchQuery} className="input-group-append button-icon">
                                        <span className="input-group-text pl-3 pr-3"><i className="fa fa-times"></i></span>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div className="col-sm-8 float-right pt-2 pb-2">
                                <button onClick={this.resetFilters} className="float-right btn btn-sm btn-danger ml-2 pr-3 pl-3"><i className="fa fa-times"></i></button>
                                <button onClick={this.toggleFilter} className="float-right btn btn-sm btn-primary ml-2"><i className="fa fa-sort-amount-down"></i> Filteren</button>
                                {isAdmin(currentUser) && <>
                                    <button onClick={this.showDeletedTickets} className={"ml-2 float-right btn btn-sm btn-outline-danger" + (showDeleted ? " active" : "")}>
                                        {showDeleted ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>} Verwijderde Tickets
                                    </button>
                                </>} 
                            </div>
                        </div>
                        <Filter toggled={filtersToggled} />
                        {filteredTickets && !!filteredTickets.length && <>
                        <table className="table table-bordered table-responsive small mt-3">
                            <thead className="thead-light text-center">
                                <tr style={{width: '100%'}}>
                                    <th style={{minWidth: '200px'}} scope="col">
                                        ID <SortButton onSort={this.sortByProp} sortOption={sortProps.ID} currentSortOption={currentSortOption} />
                                    </th>
                                    <th style={{minWidth: '150px'}} scope="col">
                                        Titel <SortButton onSort={this.sortByProp} sortOption={sortProps.TITLE} currentSortOption={currentSortOption} />
                                    </th>
                                    <th style={{minWidth: '200px'}} scope="col">
                                        Omschrijving <SortButton onSort={this.sortByProp} sortOption={sortProps.DESC} currentSortOption={currentSortOption} />
                                    </th>
                                    <th style={{minWidth: '200px'}} scope="col">
                                        Gemaakt door <SortButton onSort={this.sortByProp} sortOption={sortProps.AUTHOR} currentSortOption={currentSortOption} />
                                    </th>
                                    <th style={{minWidth: '200px'}} scope="col">
                                        Aanmaakdatum <SortButton onSort={this.sortByProp} sortOption={sortProps.CREATED_DATE} currentSortOption={currentSortOption} />
                                    </th>
                                    <th style={{minWidth: '200px'}} scope="col">
                                        Gewijzigd door <SortButton onSort={this.sortByProp} sortOption={sortProps.EDITOR} currentSortOption={currentSortOption} />
                                    </th>
                                    <th style={{minWidth: '200px'}} scope="col">
                                        Laatst gewijzigd <SortButton onSort={this.sortByProp} sortOption={sortProps.UPDATED_DATE} currentSortOption={currentSortOption} />
                                    </th>
                                    <th style={{minWidth: '200px'}}scope="col">Status <SortButton onSort={this.sortByProp} sortOption={sortProps.STATUS} currentSortOption={currentSortOption} /></th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                {currentPageTickets.map((ticket, count) => {
                                    if(count < pageSize) {
                                        return (<tr key={ticket.id}>
                                            <th><DetailsButton id={ticket.id} isDeleted={ticket.isDeleted} /></th>
                                            <td>{ticket.title}</td>
                                            <td>{ticket.content.substring(0, 25) + '...'}</td>
                                            <td><TicketUserButton user={ticket.user_created} /></td>
                                            <td><DateButton date={ticket.createdAt} /></td>
                                            <td><TicketUserButton user={ticket.user_last_edited} /></td>
                                            <td><DateButton date={ticket.updatedAt} /></td>
                                            <td><ReparatieStatusButton status={ticket.ticket_status} /></td>
                                        </tr>)
                                    }
                                    return ''
                                })}
                            </tbody>
                        </table>
                        <Pagination 
                            setPageSize={this.setPageSize} 
                            itemCount={filteredTickets.length} 
                            pageSize={pageSize} 
                            currentPage={currentPage} 
                            setPage={this.setPage} 
                        />
                        </>}
                        {!filteredTickets.length && <>
                            <hr className="pt-4" />
                            <div className="empty-results text-secondary small">
                                <i className="fa fa-folder-open"></i> Geen tickets gevonden.
                            </div>
                            <hr className="mt-5"/>
                        </>}
                        </>}
                        {!tickets && !error && <div className="loading-container">
                            <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                        </div>}
                        {error && !tickets && <>
                            <div className="text-center mt-5">
                                <h1 className="text-secondary"><i className="fa fa-exclamation-triangle"></i> Geen toegang</h1>
                                <p>Je hebt geen toegang tot deze pagina.</p>
                            </div>
                        </>}
                    </div>
                </div>
                <Modal className="small" show={!!ticketIdToDelete} onHide={this.clearTicketIdToDelete}>
                    <Modal.Header>
                        <Modal.Title>
                            <i className="fa fa-trash"></i> Verwijder Ticket
                        </Modal.Title>
                    </Modal.Header>       
                    <Modal.Body>
                        Weet je zeker dat je dit ticket wilt verwijderen?
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.deleteTicket} className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"></i> Verwijder</button>
                        <button onClick={this.clearTicketIdToDelete} className="btn btn-sm btn-outline-secondary"><i className="fa fa-times"></i> Sluiten</button>
                    </Modal.Footer>
                </Modal>
            </>

        );
    }
}

export default connect(
    state => {
        return {
            tickets: state.ticketReducer.tickets,
            currentUser: state.authReducer.currentUser,
            searchQuery: state.filterReducer.searchQuery,
            currentSortOption: state.filterReducer.currentSortOption,
            currentSortDirection: state.filterReducer.currentSortDirection,
            filtersToggled: state.filterReducer.filtersToggled,
            filterOptions: state.filterReducer.filterOptions,
            ticketIdToDelete: state.ticketReducer.ticketIdToDelete
        }
    }
) (Tickets);