import React, { Component } from 'react';
import { GET } from '../../scripts/api';
import { connect } from 'react-redux';
import TicketMessage from '../ticketmessage/TicketMessage';
import { Link, withRouter } from 'react-router-dom';
import MessageModal from '../message/MessageModal';
import ReparatieStatusButton from '../buttons/ReparatieStatusButton';
import DeleteMessageModal from '../message/DeleteMessageModal';
import AssignUser from './AssignUser';
import { getNestedChildren } from '../../scripts/arrayHelper';

class TicketDetails extends Component {

    state = {
        details: null,
        statusList: null,
        userList: null,
        assignedUsers: null,
        error: null,
        createMessage: false,
    }

    openCreateMessage = id => {
        this.setState({createMessage: true, replyId: id})
    }

    closeCreateMessage = () => {
        this.setState({createMessage: false, replyId: null})
    }

    getDetails = () => {
        GET('ticket/details',
            {id: this.props.match.params.id},
            response => this.setDetails(response),
            error => console.error(error)
        );
    }

    setDetails = response => {
        this.setState({
            details: response.details,
            statusList: response.statusList,
            userList: response.userList ? response.userList : null,
            assignedUsers: response.assignedUsers ? response.assignedUsers : null,
        })
    }

    componentDidMount() {
        this.getDetails();
    }

    attemptClearUnreadMessage = unreadMessages => {
        const ticketId = this.props.match.params.id;
        this.props.dispatch({
            type: 'SET_UNREAD_MESSAGES',
            messages: unreadMessages.filter(message => message.ticketId !== ticketId)
        });
    }

    subtractAssignedUsersFrom = userList => {
        const { assignedUsers } = this.state;
        const idsToSubtract = assignedUsers.map(user => user.userId);
        return userList.filter(user => {
            return idsToSubtract.indexOf(user.id) === -1;
        });
    }

    render() {

        const { details, createMessage, statusList, userList, assignedUsers, replyId } = this.state
        const { currentUser } = this.props


        return (
            <>
            <div className="content">
                <div className="container-fluid">
                    {!details && <div className="loading-container">
                        <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                    </div>}
                    {details && <>                        
                        <Link to={'/tickets'}><button className="float-right btn btn-sm btn-outline-secondary"><i className="fa fa-arrow-left"></i> Terug</button></Link>
                        <h3><i className="fa fa-envelope-open"></i> Ticket Details</h3>
                        <hr />
                        <div className="row">
                            <div className="col-sm-8">
                                <h5 className="pb-3"><i className="fa fa-fingerprint"></i> CC-{details.id}</h5>
                                <h6>{details.title}</h6>
                                <p>{details.content}</p>
                            </div>
                            <div className="col-sm-4">
                                <h5><i className="fa fa-cog"></i> Status</h5>

                                <div className="card text-danger">
                                    <ReparatieStatusButton large={true} status={details.ticket_status} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        {userList && <AssignUser setDetails={this.setDetails} assignedUsers={assignedUsers} users={this.subtractAssignedUsersFrom(userList)} />}
                        <div className="row mb-3">
                            <div className="col-sm-12">
                            <button onClick={() => this.openCreateMessage(null)} className="float-right btn btn-sm btn-outline-primary"><i className="fa fa-reply"></i> Plaats Bericht</button>
                            <h6 className="pt-1">Berichten</h6>
                            </div>
                        </div>
                        <hr />
                        {details.ticket_messages && getNestedChildren(details.ticket_messages, null, 'parentMessageId').map(message => {
                            return <div style={!message.isDeleted ? {borderLeft: '1px solid rgba(0,0,0,.125)'} : {}} key={message.id}><TicketMessage dispatch={this.props.dispatch} currentUser={currentUser} level={0} reply={this.openCreateMessage} message={message} /></div>
                        })}
                        {details.ticket_messages && !details.ticket_messages.length && <>
                            <div className="text-center small">
                                <span>Geen berichten</span>
                            </div>
                        </>}
                        <hr />
                    </>}
                </div>
            </div>
            <MessageModal replyId={replyId} statusList={statusList} setDetails={this.setDetails} show={createMessage} close={this.closeCreateMessage} />
            <DeleteMessageModal setDetails={this.setDetails} />
            </>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            unreadMessages: state.authReducer.unreadMessages,
            currentUser: state.authReducer.currentUser
        }
    }
) (TicketDetails));