import React, { Component } from 'react';
import DeletePageModal from './DeletePageModal';
import { connect } from 'react-redux';
import { getNestedChildren, sortByProperty } from '../../../scripts/arrayHelper';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ChildPageItem from './ChildPageItem';
import { POST } from '../../../scripts/api';
import './PageList.css';

class PageList extends Component {

    onDragEnd = (res) => {
        if(!res.destination) {
            return true;
        }

        const swapped = this.swapOrder(
            this.props.pageList,
            'pageOrder',
            res.source.index,
            res.destination.index
        )

        this.props.dispatch({
            type: 'SET_PAGE_LIST',
            pageList: swapped
        })
        this.updateOrder(swapped)
    }

    updateOrder = pages => {
        const pageIds = sortByProperty('pageOrder', getNestedChildren(pages, null, 'parentPageId')).map(page => page.id)
        POST('page/reorder',
            { pageIds },
            newPageList => {
                this.props.dispatch({
                    type: 'SET_PAGE_LIST',
                    pageList: newPageList
                })
                this.props.dispatch({
                    type: 'SET_PAGES',
                    pages: newPageList
                })
            },
            error => console.error(error)
        );
    }

    swap = (list, startIndex, endIndex) => {



        let newList = [ ...list ]
        newList[startIndex].pageOrder = endIndex;
        newList[endIndex].pageOrder = startIndex;

        return newList
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
      };

    swapOrder = (list, property, sourceOrder, destinationOrder) => {
        let newList = [ ...list ];
        const placeholder = newList.splice(sourceOrder, 1)[0]
        placeholder[property] = destinationOrder
    
        newList.forEach((question, index) => {
            let newOrder = index
            if(newOrder >= destinationOrder) {
                newOrder = index + 1
            }
            question[property] = newOrder
        })
        newList.push(placeholder)
        return newList;
    }

    render() {

        const { pageList } = this.props;
        const formattedPages = pageList;

        return (
            <div>
                <ul className="list-group">
                    {formattedPages && <DragDropContext onDragEnd={res => this.onDragEnd(res)} >
                        <Droppable  droppableId="droppable">                
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef}>
                                {formattedPages.map((page, index) => (
                                    <Draggable draggableId={page.id} index={index} key={page.id}>
                                        {(provided, snapshot) => {
                                            return (<div 
                                                className={"drag-item" + (snapshot.isDragging ? (!!snapshot.draggingOver ? " dragging" : " undroppable") : "")}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                    <div style={{borderLeft: `1px solid rgba(0,0,0,.125)`}}>
                                                        <ChildPageItem  
                                                        isDragging={snapshot.isDragging} 
                                                        isDroppable={!!snapshot.draggingOver}  
                                                        level={0} 
                                                        page={page} />
                                                    </div>
                                        </div>)}}
                                    </Draggable>)
                                )}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>}
                </ul>
                <DeletePageModal />
            </div>
        );
    }
}

export default connect() (PageList);