import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DELETE, POST } from '../../../scripts/api';

class DeleteSignupModal extends Component {

	close = () => {
        this.props.dispatch({
            type: 'SET_SIGNUP_TO_DELETE',
            pageId: null
        })
    }

    deleteSignup = () => {
        const { signupToDelete } = this.props;
        POST('signup/remove',
            { id: signupToDelete},
            data => {
				this.close()
				this.props.setMailingList(data);
			},
            error => console.error(error)
        );
    }

    render() {

        const { signupToDelete } = this.props;

        return (
            <Modal className="small" show={!!signupToDelete} onHide={this.close}>
                <Modal.Header>
                    <Modal.Title>Pagina verwijderen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je zeker dat je deze aanmelding wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.deleteSignup} className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"></i> Aanmelding verwijderen</button>
                    <button onClick={this.close} className="btn btn-sm btn-outline-secondary"><i className="fa fa-times"></i> Sluiten</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(connect(
	state => {
		return {
			signupToDelete: state.pageReducer.signupToDelete
		}
	}
) (DeleteSignupModal));