import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isAdmin } from '../../../scripts/roleHelper';
import { GET, POST } from '../../../scripts/api';
import { Link, withRouter } from 'react-router-dom';
import { hasErrors } from '../../../scripts/errorHelper';

class CreateTicket extends Component {

    state = {
        title: '',
        content: '',
        assignedUser: null,
        selectedStatus: null,
        errors: {
            title: null,
            content: null
        },
        adminUserList: null
    }

    componentWillReceiveProps(nextProps) {
        if(!this.props.currentUser && isAdmin(nextProps.currentUser)) {
            this.getAdminList();
        }
    }

    componentDidMount() {
        if(isAdmin(this.props.currentUser)) {
            this.getAdminList();
        }
    }

    areFieldsValid = () => {
        const { title, content } = this.state;
        let errors = this.state.errors;
        errors.title = title.length < 3 ? 'Voer een geldige titel in.' : null
        errors.content = content.length < 3 ? 'Voer een geldige omschrijving in.' : null
        this.setState({ errors })
        return !hasErrors(errors);
    }

    attemptCreateTicket = () => {
        if(this.areFieldsValid()) {
            this.createTicket();
        }
    }

    createTicket = () => {
        const { title, content, assignedUser, selectedStatus } = this.state;

        POST('ticket/create',
            { 
                title, 
                content, 
                assignedUser,
                statusId: selectedStatus ? selectedStatus.id : null
            },
            result => {
                this.props.history.push('/tickets');
            },
            error => console.error(error)
        );
    }

    getAdminList = () => {
        GET('tickets/assignlist',
            {},
            data => this.setState({
                adminUserList: data.adminUserList,
                statusList: data.statusList,
                selectedStatus: data.statusList.find(status => !!status.isDefault)
            }),
            error => console.error(error)
        );
    }

    updateStatus = e => {
        this.setState({selectedStatus: this.state.statusList.find(status => status.id === parseInt(e.target.value, 10))})
    }

    setAssignedUser = e => {
        this.setState({assignedUser: e.target.value})
    }

    setTitle = e => {
        this.setState({title: e.target.value})
    }

    setContent = e => {
        this.setState({content: e.target.value})
    }

    render() {

        const { title, content, errors, adminUserList, selectedStatus, statusList } = this.state;
        const { currentUser } = this.props;

        return (
            <div className="content">
                <div className="container-fluid">
                    <h3><i className="fa fa-envelope"></i> Ticket aanmaken <Link className="float-right" to={'/tickets'} ><button className="btn btn-sm btn-outline-secondary"><i className="fa fa-chevron-left"></i> Terug</button></Link></h3>
                    <hr />
                    <label className="small"><strong>Titel</strong></label>
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-paragraph mr-2 ml-2"></i></span>
                        </div>
                        <input 
                            value={title} 
                            onChange={this.setTitle} 
                            type="text" 
                            className={"form-control" + (errors.title ? (title.length >= 3 ? " is-valid" : " is-invalid") : "")}
                            id="ticket_title" 
                            placeholder="Voer een titel in" 
                        />
                        {errors.title && <div className="invalid-feedback">
                            {errors.title}
                        </div>}
                    </div>

                    <label className="small pt-3"><strong>Omschrijving</strong></label>
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-comment mr-2 ml-2"></i></span>
                        </div>
                        <textarea value={content} onChange={this.setContent} placeholder={'Vul de omschrijving in'} rows={5} className={"form-control" + (errors.content ? (content.length >= 3 ? " is-valid" : " is-invalid") : "")}>
                        </textarea>
                        {errors.content && <div className="invalid-feedback">
                            {errors.content}
                        </div>}
                    </div>
                    {isAdmin(currentUser) && <>
                        {!adminUserList && !statusList && <>
                            <div className="loading-container">
                                <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                            </div>
                        </>}
                        {adminUserList && statusList && <>
                        <label className="small pt-3"><strong>Ticket toewijzen</strong></label>
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text"><i className="fa fa-link ml-2 mr-2"></i></label>
                            </div>
                            <select onChange={this.setAssignedUser} className="custom-select" >
                                <option value={''}>Geen</option>
                                {adminUserList.map(user => {
                                    return (
                                        <option key={user.id} value={user.id}>{user.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <label className="small"><strong>Status</strong></label>
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text"><i className="fa fa-cogs mr-2 ml-2"></i></label>
                            </div>
                            <select defaultValue={(selectedStatus && selectedStatus.id) || null} onChange={this.updateStatus} className="custom-select" >
                                {statusList.map(status => {
                                    return (
                                        <option key={status.id} value={status.id}>{status.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        </>}
                    </>}
                    <button onClick={this.attemptCreateTicket} className="mt-3 float-right btn btn-sm btn-outline-success"><i className="fa fa-envelope"></i> <i className="fa fa-plus fa-xs fa-add-extend"></i> Ticket Aanmaken</button>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            currentUser: state.authReducer.currentUser
        }
    }
)(CreateTicket));