import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DELETE, POST } from '../../../scripts/api';

class DeleteStatusModal extends Component {

    state = {
        newStatusId: null,
        statusError: null,
    }

    close = () => {
        this.setState({newStatusId: null, statusError: null})
        this.props.dispatch({
            type: 'SET_STATUS_TO_DELETE',
            statusId: null
        })
    }

    setStatusId = e => {
        this.setState({newStatusId: e.target.value})
    }

    attemptDeleteStatus = () => {
        let statusError = null
        const { newStatusId } = this.state;
        if(!newStatusId) {
            statusError = "Selecteer een nieuwe status";
        }
        this.setState({statusError})
        if(!statusError) {
            this.deleteMessage(newStatusId)
        }
    }

    deleteMessage = newStatusId => {

        const { statusIdToDelete } = this.props;

        POST('ticket-status/remove', 
            {
                newStatusId,
                statusIdToDelete
            },
            statusList => {
                this.props.dispatch({
                    type: 'SET_STATUS_LIST',
                    statusList
                })
                this.close();
            },
            error => console.error(error)
        );
    }

    render() {

        const { statusIdToDelete, statusList } = this.props;
        const { statusError } = this.state;

        return (
            <Modal show={!!statusIdToDelete} onHide={this.close}>
                <Modal.Header>
                    <Modal.Title>Status Verwijderen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="small">Weet je zeker dat je deze status wilt verwijderen? Update de tickets met deze status naar een nieuwe status door hieronder te kiezen.</p>
                    <div className="input-group input-group-sm mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-pen mr-2 ml-2"></i></span>
                        </div>
                        <select onChange={this.setStatusId} className="custom-select" >
                                <option value={''}>Geen</option>
                                {statusList.filter(status => status.id !== statusIdToDelete).map(status => {
                                    return (
                                        <option key={status.id} value={status.id}>{status.name}</option>
                                    )
                                })}
                        </select>
                        {statusError && <div className="invalid-feedback d-inline">{statusError}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    
                    <button onClick={this.attemptDeleteStatus} className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"></i> Status verwijderen</button>
                    <button onClick={this.close} className="btn btn-sm btn-outline-secondary"><i className="fa fa-times"></i> Sluiten</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            statusIdToDelete: state.statusReducer.statusIdToDelete,
            statusList: state.statusReducer.statusList
        }
    }
) (DeleteStatusModal));