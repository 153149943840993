import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import TicketUserButton from '../buttons/TicketUserButton';
import { connect } from 'react-redux';
import { PUT } from '../../scripts/api';
// import Pusher from 'pusher';

class MessagePanel extends Component {

    toggleNotifications = () => {
        window.$('.keep-open').toggleClass('show');
    }

    handleNotificationPanelSettings = () => {
        window.$('body').on('click', function (e) {
            if (!window.$('.keep-open').is(e.target) 
                && window.$('.keep-open').has(e.target).length === 0 
                && window.$('.show').has(e.target).length === 0
            ) {
                window.$('.keep-open').removeClass('show');
            }
        });
    }

    componentDidMount() {
        try {
            // this.connectToNotifications(this.props.currentUser);
            this.handleNotificationPanelSettings();
        } catch (error) {
            console.error(error)
            console.log("Pusher API might need a new CDN link?")
        }
    }

    // connectToNotifications = user => {
    //     try {
    //         Pusher.logToConsole = true;

    //         var pusher = new Pusher('6a043bd395f795d98df6', {
    //             cluster: 'eu',
    //             forceTLS: true
    //         });

    //         var channel = pusher.subscribe('user-' + user.id);
    //         channel.bind('notification', data => {
    //             console.log(data)
    //             this.props.dispatch({
    //                 type: 'SET_UNREAD_MESSAGES',
    //                 messages: [
    //                     ...this.props.unreadMessages,
    //                     data
    //                 ]
    //             })
    //         });

    //         channel.bind('pusher:subscription_succeeded', data => {
    //             console.log('Socket connection established.')
    //         });
    //     } catch (error) {
    //         console.log(error)
    //     }
       
    // }

    goToTicket = (ticketId, unreadMessageId) => {
        this.props.history.push('/ticket-details/' + ticketId);
        this.clearNotification(unreadMessageId);
    }

    clearNotification = unreadMessageId => {
        PUT('read-message', 
            { unreadMessageId },
            result => this.props.dispatch({
                type: 'SET_UNREAD_MESSAGES',
                messages: [
                    ...this.props.unreadMessages.filter(message => {
                        return message.id !== unreadMessageId
                    }),
                ]
            }),
            err => console.error(err)
        );
    }

    render() {

        const { unreadMessages } = this.props

        return (
            <div className="btn-group" role="group">
                <Link to={'/tickets'}>
                    <button type="button" className="btn btn-sm btn-primary"><i className="fa fa-envelope"></i></button>
                </Link>
                {unreadMessages && 
                <button type="button" className="btn btn-sm btn-primary rounded-right" onClick={this.toggleNotifications} >
                    <span className={"badge badge-" + (!!unreadMessages.length ? "danger" : "light")}> <span className="ml-1 mr-1">{unreadMessages.length}</span> </span>
                </button>}
                <div style={{minWidth: '20rem'}} className="keep-open dropdown-menu dropdown-menu-center pt-0 pb-0">
                    <h6 className="dropdown-header text-center pt-3 pb-0"><i className="fa fa-bell"></i> Meldingen</h6>
                    <hr className="mb-0"/>
                    {!!unreadMessages.length && unreadMessages.slice(0, 4).map(message => {
                        return (<span key={message.id} className="dropdown-item-text small border-bottom pt-2 pb-2 pr-3 pl-3">
                            <strong><TicketUserButton user={message.user} addText="heeft gereageerd" /></strong>
                            <div className="row pt-2 pb-2">
                                <div className="col-sm-10 pr-1">
                                        <button onClick={() => this.goToTicket(message.ticketId, message.id)} className="btn btn-block btn-sm btn-outline-dark mr-2">
                                            <i className="fa fa-fingerprint"></i> {"CC-" + message.ticketId}
                                        </button>
                                </div>
                                <div className="col-sm-2 pl-1">
                                    <button onClick={() => this.clearNotification(message.id)} className="btn-block float-right btn btn-sm btn-outline-danger"><i className="fa fa-times"></i></button>
                                </div>
                            </div>
                        </span>)
                    })}
                    {!unreadMessages.length && <>
                        <div className="text-center pt-3 pb-3 small">
                            <i>Geen nieuwe meldingen</i>
                        </div> 
                        <hr className="mb-0 mt-0"/>
                    </>}
                    <Link to={"/tickets"}><h6 className="dropdown-header text-center pt-3 pb-3"><i className="fa fa-envelope-open-text"></i> Naar Ticketcentrum</h6></Link>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            currentUser: state.authReducer.currentUser,
            unreadMessages: state.authReducer.unreadMessages
        }
    }
) (MessagePanel));