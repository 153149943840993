import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Col } from 'react-bootstrap';
import './NavItem.css';

class CustomToggle extends React.Component{
	constructor(props, context) {
		super(props, context);
	  }
	
	  handleClick = (e) => {
		e.preventDefault();
	
		this.props.onClick(e);
	  }
	
	  render() {
		return (
		  <span className="py-3" onClick={this.handleClick}>
				{this.props.children}
		  </span>
		);
	  }
}


class NavItem extends Component {

	gradient = ["#f0f1f2","#e9eaea","#e1e2e3","#d9dadb","#d2d2d3","#cacbcb","#c2c3c3","#bbbbbc","#b3b3b4","#abacac","#a3a4a4","#9c9c9c","#949494","#8c8d8d","#858585"]

	state = {
		childrenExpanded: false
	}

	openChildren = () => this.setState({childrenExpanded: true})
	closeChildren = () => this.setState({childrenExpanded: false})

	toggleChildrenExpanded = () => this.setState({childrenExpanded: !this.state.childrenExpanded})

	render() {
		const { page, path, level } = this.props;
		const { childrenExpanded } = this.state;
		const isActive = '/' + page.pageUrl === path || (page.isHomepage && '/' === path)

		return (
			<>
				<div>
					{page.children && <div onClick={this.toggleChildrenExpanded} className="page-dropdown-item pl-3 pr-1 py-2 small">
						<span >{page.title}</span> <i className="fa fa-caret-down fa-sm my-1 mx-2 float-right"></i>
					</div>}
					{!page.children && <Link to={'/' + (!!page.isHomepage ? '' : page.pageUrl)}>
						<div className="page-dropdown-item pl-3 pr-3 py-2 small">
							<span className={isActive ? "text-primary font-weight-bold" : ""}>{page.title} </span>
						</div>
					</Link>}
					{page.children && <Collapse in={childrenExpanded}>
						<div className="page-children" style={{background: `${this.gradient[level % this.gradient.length]}`}}>
							{page.children.map(childPage => {
								return (<span key={childPage.id}>
									<NavItem level={level + 1} path={path} page={childPage} />
								</span>)
							})}
						</div>
					</Collapse>}
				</div>
			</>
		);
	}
}

export default NavItem;