import React, { Component } from 'react';

class NameInput extends Component {
    render() {

        const { name, error, setName } = this.props

        return (
            <>
                <label className="small"><strong>Naam</strong></label>
                <div className="input-group input-group-sm">
                    <div className="input-group-prepend">
                        <span className="input-group-text"><i className="fa fa-user mr-2 ml-2"></i></span>
                    </div>
                    <input 
                        value={name} 
                        onChange={e => setName(e.target.value)} 
                        type="text" 
                        className={"form-control" + (error ? (name.length >= 3 ? " is-valid" : " is-invalid") : "")}
                        id="edit_name" 
                        placeholder="Voer nieuwe naam in" 
                    />
                    {error && <div className="invalid-feedback">
                        {error}
                    </div>}
                </div> 
            </>
        );
    }
}

export default NameInput;