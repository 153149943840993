
const initialState = {
    statusIdToDelete: null,
    statusList: null,
    editingStatusId: null,
    createNewStatus: false
}

export default function pageReducer(state = initialState, action) {
    switch(action.type) {
        case 'SET_STATUS_TO_EDIT': {
            return {
                ...state,
                editingStatusId: action.statusId
            }
        }
        case 'CREATE_NEW_STATUS': {
            return {
                ...state,
                createNewStatus: true
            }
        }
        case 'STOP_CREATE_NEW_STATUS': {
            return {
                ...state,
                createNewStatus: false
            }
        }
        case 'SET_STATUS_LIST': {
            return {
                ...state,
                statusList: action.statusList
            }
        }
        case 'SET_STATUS_TO_DELETE': {
            return {
                ...state,
                statusIdToDelete: action.statusId
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}