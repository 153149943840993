import React, { Component } from 'react';
import { GET } from './../scripts/api';
import { getFromLocalStorage } from './../scripts/localStorage';
import { connect } from 'react-redux';
import Navigation from './navigation/Navigation';
import Routes from './routes/Routes';
import { withRouter } from 'react-router-dom';
import Footer from './footer/Footer';
import './App.css';
import Login from './login/Login';
import Register from './register/Register';

class App extends Component {

	tokenName = 'CompuToken';

  	componentDidMount() {
		this.attemptAuthentication();
	}

	disableAuthLoading = () => {
		this.props.dispatch({
			type: 'DISABLE_AUTH_LOADING'
		})
	}
	  
	attemptAuthentication = () => {
		if(getFromLocalStorage(this.tokenName)) {
			GET('user/login/authenticate', 
			{},
			data => {
				this.disableAuthLoading()
				this.props.dispatch({
					type: 'SET_UNREAD_MESSAGES',
					messages: data.unreadTickets
				})
				this.props.dispatch({
					type: 'SET_CURRENT_USER',
					user: data.user
				});
			},
			() => {
				this.disableAuthLoading()
				console.warn('Unable to authenticate using token.')
			});
		} else {
			this.disableAuthLoading()
		}
	}

	render() {
	return (
			<div className="app">
				<Navigation />
				<Routes />
				<Footer />
				<Login />
				<Register />
			</div>
		);
  	}
}

export default withRouter(connect() (App));
