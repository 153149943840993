import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App.jsx';
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers } from 'redux';
import authReducer from './reducers/authReducer';
import filterReducer from './reducers/filterReducer';
import statusReducer from './reducers/statusReducer';
import pageReducer from './reducers/pageReducer';
import ticketReducer from './reducers/ticketReducer';
import userReducer from './reducers/userReducer';
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom';

const rootReducer = combineReducers({
    authReducer,
    filterReducer,
    ticketReducer,
    pageReducer,
    statusReducer,
    userReducer, // lol
})

ReactDOM.render(
    <Provider store={createStore(rootReducer)}>
        <BrowserRouter>
            <Route path="/" component={App} />
        </BrowserRouter>
    </Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
