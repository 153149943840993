import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isAdmin } from '../../scripts/roleHelper';
import { POST } from '../../scripts/api';

class DetailsButton extends Component {

    deleteTicket = ticketId => {
        this.props.dispatch({
            type: 'SET_TICKET_TO_DELETE',
            ticketId
        })
    }

    restoreTicket = ticketId => {
        POST('ticket/restore',
            { id: ticketId },
            newTickets => this.props.dispatch({
                type: 'SET_TICKETS',
                tickets: newTickets
            }),
            error => console.error(error)
        );
    }

    render() {
        const { id, currentUser, isDeleted } = this.props
        return (
            <div>
                <Link to={'/ticket-details/' + id}>
                    <button className="disabled btn btn-sm btn-outline-dark mr-2">
                        <i className="fa fa-fingerprint"></i> {"CC-" + id}
                    </button>
                </Link>
                {isAdmin(currentUser) && !isDeleted && <button onClick={() => this.deleteTicket(id)} className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"></i></button>}
                {isAdmin(currentUser) && !!isDeleted && <button onClick={() => this.restoreTicket(id)} className="btn btn-sm btn-outline-success"><i className="fa fa-recycle"></i></button>}
            </div>
        );
    }
}

export default connect(
    state => {
        return {
            currentUser: state.authReducer.currentUser
        }
    }
) (DetailsButton);