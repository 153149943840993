const DEV = 'development'
const PROD = 'production'

export function getEnvVariables() {
    if(process.env.NODE_ENV === PROD) {
        return {
            apiUrl: window.location.origin + '/v1/api'
        }
    }

    if(process.env.NODE_ENV === DEV) {
        return {
            apiUrl: 'http://localhost:8000/api'
        }
    }
}