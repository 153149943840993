import React, { Component } from 'react';
import { POST2, GET } from '../../../scripts/api';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { hasErrors } from '../../../scripts/errorHelper';
import TitleContainer from './inputs/TitleContainer';
import HiddenMenuContainer from './inputs/HiddenMenuContainer';
import WysiwygContainer from './inputs/WysiwygContainer';
import ParentPageContainer from './inputs/ParentPageContainer';
import IsCategoryContainer from './inputs/IsCategoryContainer';
import HomepageContainer from './inputs/HomepageContainer';

class EditPage extends Component {

    maxContentLength = Math.pow(2,16);

    state = {
        pageId: null,
        title: '',
        content: '',
        isHidden: false,
        parentPageId: null,
        isCategory: false,
        isHomepage: false,
        errors: {
            title: null,
            content: null
        },
    }

    componentDidMount() {
        GET('admin/pages/details',
            {id: this.props.match.params.id},
            details => {
                this.setState({
                    pageId: details.id,
                    title: details.title,
                    content: details.content,
                    isHidden: details.isHidden,
                    parentPageId: details.parentPageId,
                    isCategory: details.isCategory,
                    isHomepage: details.isHomepage
                })
                // window.$('#summernote').summernote('code', details.content);
            },
            error => console.error(error)
        );
    }

    setTitle = e => this.setState({title: e.target.value, errors: { ...this.state.errors, title: null}})
    setHidden = e => this.setState({isHidden: e.target.checked})        
    setContent = content => this.setState({content, errors: { ...this.state.errors, content: null}})
    setParentPage = id => this.setState({parentPageId: id})
    toggleCategory = () => this.setState({isCategory: !this.state.isCategory})
    toggleHomepage = () => this.setState({isHomepage: !this.state.isHomepage})

    attemptEditPage = () => {
        if(this.validatePageEdit()) {
            this.editPage();
        }
    }

    validatePageEdit = () => {
        let errors = { ...this.state.errors };
        const { title } = this.state;
        const content = window.$('#summernote').summernote('code');
        errors.title = title.length < 3 ? "Voer een geldige titel in" : null;
        errors.content = content.length < 15 || content.length > this.maxContentLength ? 'Voer een geldige inhoud in.' : null;
        this.setState({ errors })
        return !hasErrors(errors);
    }

    editPage = () => {

        const content = window.$('#summernote').summernote('code');
        const { title, isHidden, pageId, parentPageId, isCategory, isHomepage } = this.state;
        
        // Use post 2 because axios doesn't do an options check
        // which sends full content as an encoded url (lol)
        POST2('admin/pages/edit',
            {
                pageId,
                title,
                content,
                isHidden,
                parentPageId,
                isCategory,
                isHomepage
            },
            pages => {
                this.props.dispatch({
                    type: 'SET_PAGES',
                    pages
                })
                this.props.history.push('/admin/pages');
            },
            err => console.error(err)
        );
    }

    render() {

        const { title, errors, isHidden, content, parentPageId, isCategory, isHomepage } = this.state;


        return (
            <div className="content">
                <div className="container-fluid">
                    <h3 className="pb-3">
                        <i className="fa fa-file-alt"></i> Pagina wijzigen
                        <Link to={'/admin/pages'} ><button type="button" className="float-right btn btn-sm btn-outline-secondary"><i className="fa fa-chevron-left"></i> Terug</button></Link>
                    </h3>
                    <TitleContainer 
                        title={title} 
                        error={errors.title} 
                        setTitle={this.setTitle} 
                    />
                    <ParentPageContainer 
                        setParentPage={this.setParentPage}
                        selectedPageId={parentPageId}
                    />
                    <HomepageContainer
                        isHomepage={isHomepage}
                        toggleHomepage={this.toggleHomepage}
                    />
                    <HiddenMenuContainer 
                        isHidden={isHidden}
                        setHidden={this.setHidden}
                    />
                    {/* <IsCategoryContainer
                        toggleCategory={this.toggleCategory}
                        isCategory={isCategory}
                    /> */}
                    <WysiwygContainer 
                        content={content}
                        setContent={this.setContent}
                    />
                    <div className="row">
                        <div className="col-sm-12">
                            <button onClick={this.attemptEditPage} disabled={!!errors.content} className="float-right mt-3 btn-sm btn btn-warning"><i className="fa fa-edit"></i> Pagina Wijzigen</button>
                            {errors.content && <div className="text-danger small pt-3">
                                {errors.content}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
        }
    }
) (EditPage));