import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PrevPageButton extends Component {
    render() {

        const { to } = this.props;

        return (
            <Link to={to} >
                <button type="button" className="float-right btn btn-sm btn-outline-secondary mr-2">
                    <i className="fa fa-chevron-left"></i> Terug
                </button>
            </Link>
        );
    }
}

export default PrevPageButton;