import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DELETE, POST } from '../../scripts/api';

class DeleteMessageModal extends Component {

    deleteMessage = () => {
        const { messageIdToDelete } = this.props;
        POST('ticket-message/remove',
            {   
                messageId: messageIdToDelete,
                ticketId: this.props.match.params.id 
            },
            newDetails => {
                this.props.setDetails(newDetails);
                this.close();
            },
            error => console.error(error)
        );
    }

    close = () => {
        this.props.dispatch({
            type: 'SET_MESSAGE_TO_DELETE',
            messageId: null
        });
    }

    focusDelete = () => {
        document.getElementById('delete-btn').focus();
    }

    render() {

        const { close, messageIdToDelete } = this.props;

        return (
            <Modal onEnter={this.focusDelete} className="delete-modal" show={!!messageIdToDelete} onHide={close}>
                <Modal.Header>
                    <Modal.Title>Bericht Verwijderen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je zeker dat je dit bericht wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.deleteMessage} id="delete-btn" className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"></i> Verwijder</button>
                    <button onClick={this.close} className="btn btn-sm btn-outline-secondary"><i className="fa fa-times"></i> Sluiten</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            currentUser: state.authReducer.currentUser,
            messageIdToDelete: state.ticketReducer.messageIdToDelete
        }
    }
) (DeleteMessageModal));