import React, { Component } from 'react';
import { POST } from './../../scripts/api';
import { isValidEmail, isSafePassword } from '../../scripts/formValidator';
import { addToLocalStorage } from './../../scripts/localStorage';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { hasErrors } from '../../scripts/errorHelper';
import { Modal } from 'react-bootstrap';

class Register extends Component {

    tokenName = 'CompuToken';

    state = {
        isLoading: false,
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phonenumber: '',
        errors: {
            name: null,
            email: null,
            password: null,
            password_confirmation: null,
            phonenumber: null,
        }
    }

    clearErrors = () => {
        let errors = { ...this.state.errors }
        Object.keys(errors).forEach(key => errors[key] = null);
        this.setState({ errors });
    }

    attemptRegistration = () => {
        if(this.areRegistrationFieldsValid()) {
            this.register();
        }
    }

    areRegistrationFieldsValid = () => {
        let errors = {}
        const { name, email, password, password_confirmation } = this.state;
        errors.email = !isValidEmail(email) ? 'Voer een geldig email adres in.' : null
        errors.name = name.length < 3 ? 'Voer uw volledige naam in.' : null
        errors.password = !isSafePassword(password) ? 'Voer een wachtwoord in (minimaal 6 karakters).' : null
        errors.password_confirmation = password !== password_confirmation ? 'De wachtwoorden komen niet overeen.' : null
        this.setState({ errors })
        return !hasErrors(errors);
    }

    register = () => {
        const { email, name, phonenumber, password, password_confirmation } = this.state;

        this.enableLoading();

		POST('user/register', {
            email,
            name,
            phonenumber,
            password,
            password_confirmation
		},
		data => {
            this.stopLoading();
			this.props.dispatch({
				type: 'SET_CURRENT_USER',
				user: data.user
			})
            addToLocalStorage(this.tokenName, data.token);
            this.close();
            this.props.history.push('/');
		},
		error => {
            let errors = { ...this.state.errors }
            Object.keys(error).forEach(key => {
                errors[key] = error[key];    
            })
            this.setState({ errors })
            this.stopLoading();

        })
    }

    set = (property, e) => {
        this.setState({
            errors: { ...this.state.errors, [property]: null},
            [property]: e.target.value
        })
    }

    close = () => {
        this.props.dispatch({
            type: 'TOGGLE_REGISTER'
        })
    }

    enableLoading = () => {
        this.setState({
            loading: true
        })
    }

    stopLoading = () => {
        this.setState({
            loading: false
        })
    }
    
    render() {

        const { name, email, password, password_confirmation, phonenumber, errors, loading } = this.state;

        const { currentUser, registerOpen } = this.props

        return (
            <Modal centered show={registerOpen && !currentUser} onHide={() => this.close()}>
                <div className="container-fluid">
                        <div className="card-body">
                            <i onClick={() => this.close()} className="fa fa-times float-right"></i>
                            <br />
                            <h5 className="card-title text-center">Aanmelden</h5>
                            <h6 className="card-subtitle mb-2 text-muted text-center">CompuCorner Ticket Centrum</h6>
                            <hr/>
                            {(loading || currentUser) && <div className="loading-container">
                                <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                            </div>}
                            {(!loading && !currentUser) && <form>
                                <div className="form-group mb-3">
                                    <label className="small" htmlFor="register_name"><strong>Volledige naam</strong></label>
                                    <div className="input-group input-group-sm">
                                        {/* <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fa fa-user"></i></span>
                                        </div> */}
                                        <input 
                                            onKeyDown={e => e.key === "Enter" && this.attemptRegistration()}
                                            value={name} 
                                            onChange={val => this.set('name', val)} 
                                            type="text" 
                                            className={"form-control form-control-sm " + (errors.name ? "is-invalid" : (name.length > 3 ? "is-valid" : ""))} 
                                            id="register_name" 
                                            placeholder="Voer uw volledige naam in" 
                                        />
                                        {errors.name && <div className="invalid-feedback">
                                            {errors.name}
                                        </div>}
                                    </div>
                                    
                                </div>
                            <div className="form-group mb-3">
                                <label className="small" htmlFor="register_email"><strong>Email</strong></label>
                                <div className="input-group input-group-sm">
                                    {/* <div className="input-group-prepend">
                                        <span className="input-group-text">@</span>
                                    </div> */}
                                    <input 
                                        value={email} 
                                        onKeyDown={e => e.key === "Enter" && this.attemptRegistration()}
                                        onChange={val => this.set('email', val)} 
                                        type="email" 
                                        className={"form-control form-control-sm " + (errors.email ? "is-invalid" : (isValidEmail(email) ? "is-valid" : ""))} 
                                        id="register_email" 
                                        placeholder="Voer email adres in" 
                                    />
                                    {errors.email && <div className="invalid-feedback">
                                        {errors.email}
                                    </div>}
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="small" htmlFor="register_email"><strong>Telefoon (Optioneel)</strong></label>
                                    <div className="input-group input-group-sm">
                                        {/* <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fa fa-phone"></i></span>
                                        </div> */}
                                        <input 
                                            onKeyDown={e => e.key === "Enter" && this.attemptRegistration()}
                                            value={phonenumber} onChange={val => this.set('phonenumber', val)} 
                                            type="text" 
                                            className={"form-control form-control-sm " + (errors.phonenumber ? "is-invalid" : "")} 
                                            id="register_phonenumber" 
                                            placeholder="Voer uw telefoonnummer in" 
                                        />
                                        {errors.phonenumber && <div className="invalid-feedback">
                                            {errors.phonenumber}
                                        </div>}
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="small" htmlFor="register_password"><strong>Wachtwoord</strong></label>
                                    <div className="input-group input-group-sm">
                                        {/* <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fa fa-user-lock"></i></span>
                                        </div> */}
                                        <input 
                                            onKeyDown={e => e.key === "Enter" && this.attemptRegistration()}
                                            value={password} 
                                            type="password" 
                                            onChange={val => this.set('password', val)} 
                                            className={"form-control form-control-sm " + (errors.password ? "is-invalid" : (isSafePassword(password) ? "is-valid" : ""))} 
                                            id="register_password" 
                                            placeholder="Voer een wachtwoord in" 
                                        />
                                        {errors.password && <div className="invalid-feedback">
                                            {errors.password}
                                        </div>}
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label className="small" htmlFor="register_password_confirmation"><strong>Bevestig Wachtwoord</strong></label>
                                    <div className="input-group input-group-sm">
                                        {/* <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fa fa-lock"></i></span>
                                        </div> */}
                                        <input 
                                            onKeyDown={e => e.key === "Enter" && this.attemptRegistration()}
                                            value={password_confirmation} 
                                            type="password" 
                                            onChange={val => this.set('password_confirmation', val)} 
                                            className={"form-control form-control-sm " + (errors.password_confirmation ? "is-invalid" : (password.length > 0 && password === password_confirmation ? "is-valid" : ""))} 
                                            id="register_password_confirmation" 
                                            placeholder="Herhaal uw wachtwoord" 
                                        />
                                        {errors.password_confirmation && <div className="invalid-feedback">
                                            {errors.password_confirmation}
                                        </div>}
                                    </div>
                              
                                </div>
                            <button onClick={this.attemptRegistration} type="button" className="btn btn-primary w-100"> Aanmelden</button>
                            <br />
                            <br />
                        </form>}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            registerOpen: state.authReducer.registerOpen,
            currentUser: state.authReducer.currentUser,
        }
    }
) (Register));