import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { POST } from '../../../scripts/api';
import IconPicker from '../../icons/IconPicker';
import ColorPicker from '../../color/ColorPicker';
import { isValidHexColor } from '../../../scripts/colorHelper';
import { iconList } from '../../icons/iconList';
import { hasErrors } from '../../../scripts/errorHelper';

class EditStatusModal extends Component {

    state = {
        editingStatus: {},
        errors: {
            name: null,
            description: null,
            symbol: null,
            color: null,
        }
    }

    close = () => {
        this.setState({editingStatus: {} })
        this.props.dispatch({
            type: 'SET_STATUS_TO_EDIT',
            statusId: null
        })
    }

    set = (e, property) => {
        this.state.editingStatus.hasOwnProperty(property) && this.setState({
            editingStatus: { 
                ...this.state.editingStatus, 
                [property]: e.target.value 
            }
        });
    }

    attemptCreateStatus = () => {
        if(this.validateNewStatus()) {
            this.updateStatus();
        }
    }

    validateNewStatus = () => {
        const { editingStatus } = this.state;
        let errors = { ...this.state.errors }

        errors.description = editingStatus.description.length < 3 ? "Voer een omschrijving in" : null;
        errors.name = editingStatus.name.length < 3 ? "Voer een naam in" : null;
        errors.symbol = iconList().indexOf(editingStatus.symbol) === -1 ? "Dit is geen geldig symbool" : null
        errors.color = !isValidHexColor(editingStatus.color) ? 'Dit is geen geldige kleur' : null;
        this.setState({errors})
        return !hasErrors(errors);

    }

    updateStatus = () => {
        const { editingStatus } = this.state;
        const { id, name, color, description, symbol } = editingStatus;

        POST('ticket-status/update', 
            {
                id,
                name,
                color,
                description,
                symbol
            },
            statusList => {
                this.props.dispatch({
                    type: 'SET_STATUS_LIST',
                    statusList
                })
                this.props.dispatch({
                    type: 'SET_STATUS_TO_EDIT',
                    statusId: null
                })
                this.close()
            },
            error => console.error(error)
        );
    }

    componentWillReceiveProps(nextProps) {
        const { statusList, editingStatusId } = nextProps;
        if(statusList && editingStatusId) {
            this.setState({editingStatus: {
                ...statusList.find(status => {
                    return status.id === editingStatusId
                })
            }})
        }
    }

    getStatusById = id => {
        const { statusList } = this.props;
        if(!statusList || !id) {
            return null
        }
        return statusList.find(status => {
            return status.id === id
        })
    }

    render() {

        const { errors, editingStatus } = this.state;
        const { editingStatusId } = this.props;

        return (
            <Modal show={!!editingStatusId} onHide={this.close}>
                <Modal.Header>
                    <Modal.Title>Status aanpassen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="small"><strong>Status Naam</strong></label>
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-bars mr-2 ml-2"></i></span>
                        </div>
                        <input 
                            value={editingStatus.name} 
                            onChange={e => this.set(e, 'name')} 
                            type="text" 
                            className={"form-control" + (errors.name ? (editingStatus.name.length >= 3 ? " is-valid" : " is-invalid") : "")}
                            id="ticket_title" 
                            placeholder="Voer een naam in voor de status" 
                        />
                        {errors.name && <div className="invalid-feedback">
                            {errors.name}
                        </div>}
                    </div>

                    <label className="small mt-3"><strong>Status Omschrijving</strong></label>
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-bars mr-2 ml-2"></i></span>
                        </div>
                        <textarea value={editingStatus.description} onChange={e => this.set(e, 'description')} placeholder={'Voer omschrijving in'} rows={5} className={"form-control" + (errors.description ? (editingStatus.description.length >= 3 ? " is-valid" : " is-invalid") : "")}>
                        </textarea>
                        {errors.description && <div className="invalid-feedback">
                            {errors.description}
                        </div>}
                    </div>
                    <IconPicker icon={editingStatus.symbol} setIcon={symbol => this.setState({editingStatus: {...this.state.editingStatus, symbol } })} />
                    {errors.symbol && <div className="invalid-feedback d-inline">
                        {errors.symbol}
                    </div>}
                    <ColorPicker color={editingStatus.color} setColor={color => this.setState({editingStatus: {...this.state.editingStatus, color } })} />
                    {errors.color && <div className="invalid-feedback d-inline">
                        {errors.color}
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.attemptCreateStatus} className="btn btn-sm btn-outline-warning"><i className="fa fa-edit"></i> Status wijzigen</button>
                    <button onClick={this.close} className="btn btn-sm btn-outline-secondary"><i className="fa fa-times"></i> Sluiten</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            editingStatusId: state.statusReducer.editingStatusId,
            statusList: state.statusReducer.statusList
        }
    }
) (EditStatusModal));