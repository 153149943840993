import React, { Component } from 'react';
import './Tooltip.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

class TooltipItem extends Component {

    componentDidMount() {
        window.$('body').tooltip({ trigger: 'hover' });
    }

    render() {

        const { text, onClick, placement } = this.props;

        return (
            <span>
            <OverlayTrigger 
                key={text} 
                placement={placement || "top"}
                overlay={<Tooltip  id={text}>{text}</Tooltip>}>
                <span onClick={onClick}>
                    {this.props.children}
                </span>
            </OverlayTrigger>
            </span>
            // <span 
            //     onClick={onClick}
            //     className="d-inline-block tooltip-item" 
            //     tabIndex="0" 
            //     data-toggle="tooltip" 
            //     title={text}>
            //     <span style={{pointerEvents: 'none'}}>
        );
    }
}

export default TooltipItem;