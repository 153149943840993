import React, { Component } from 'react';
import { HuePicker, TwitterPicker } from 'react-color';
import { generateColorList, isValidHexColor } from '../../scripts/colorHelper';
import './ColorPicker.css';

class ColorPicker extends Component {

    state = {
        modalOpen: false,
    }

    toggleColorPicker = () => {
        // @TODO Dispatch this
        this.setState({modalOpen: !this.state.modalOpen});
    }

    changeDirect = e => {
        this.props.onChange(e.target.value);
    }

    changeColor = color => {
        this.props.setColor(color.hex)
    }

    render() {

        const { color } = this.props;
        const validColor = isValidHexColor(color) ? color : "#000000";

        return (
            <div>
                <div className="form-group small mb-0 mt-3">
                    <label><strong>Kleur</strong></label>
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <span onClick={this.toggleColorPicker} className="input-group-text">
                                <i className="fa fa-paint-brush ml-2 mr-2"></i>
                            </span>
                        </div>
                        <input className="form-control" value={color} onChange={() => {}} placeholder="#000000" />
                            <div className="input-group-append">
                                <span onClick={this.toggleColorPicker} className="input-group-text">
                                    <i className="pr-2 pl-2 fa fa-square" style={{color: color || "#000000"}}></i>
                                </span>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="hue-pick-container">
                                        <HuePicker width={"100%"} color={color} onChangeComplete={this.changeColor} />
                                    </span>
                                    <TwitterPicker triangle={'hide'} width={"102%"} colors={generateColorList(validColor)} color={validColor} onChange={this.changeColor} />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}

export default ColorPicker;