import React, { Component } from 'react';
import { isAdmin } from '../../scripts/roleHelper'; 
import MessagePanel from './MessagePanel';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { removeFromLocalStorage } from '../../scripts/localStorage';
import { withRouter } from 'react-router-dom';

class UserButtons extends Component {

    signOut = () => {
        this.props.dispatch({
            type: 'SET_CURRENT_USER',
            user: null
        })
        this.props.history.push('/');
        removeFromLocalStorage('CompuToken');
    }

    openLogin = () => {
        this.props.dispatch({
            type: 'TOGGLE_LOGIN'
        })
    }

    openRegister = () => {
        this.props.dispatch({
            type: 'TOGGLE_REGISTER'
        })
    }

    render() {

        const { unreadMessages, currentUser, authLoading } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-xl-4 col-lg-6">
                        <div className="logo pt-2 pb-2">
                            <Link to={"/"} ><h3 className="cc-title cc-font">CompuCorner</h3></Link>
                            <h6 className="cc-subtitle cc-font">De specialist van het groene hart</h6>
                        </div>
                    </div>
                    {authLoading && <div className="col-xl-8 col-lg-6 text-right pt-2">
                        <div className="spinner-border text-secondary spinner-border-sm mt-3 mb-3" role="status"></div>
                    </div>}
                    {!authLoading && <>
                        <div className="col-xl-8 col-lg-6">
                            <div className="btn-toolbar float-right pl-3" role="toolbar">
                            {isAdmin(currentUser) && <>
                                <span className="nav-item">
                                    <Link to={'/admin'}  className="nav-link pr-2 pb-1 pl-0">
                                        <button className="btn btn-sm btn-danger">
                                            <i className="fa fa-users-cog"></i>
                                        </button>
                                    </Link>
                                </span>
                            </>}
                            {currentUser && <>
                                <span className="nav-item">
                                    <span className="nav-link pr-2 pb-1 pl-0">
                                        <MessagePanel messages={unreadMessages} />
                                    </span>
                                </span>
                                <span className="nav-item">
                                    <Link to={'/profile'}  className="nav-link pr-2 pb-1 pl-0">
                                        <button className="btn btn-sm btn-light border-secondary">
                                            <i className="fa fa-user-circle"></i> Welkom, {currentUser.name}!
                                        </button>
                                    </Link>
                                </span>
                            </>}
                            {!currentUser && <>
                                <span className="nav-item pr-2 pt-2 mb-1">
                                    <button onClick={() => this.openRegister()} className="btn btn-sm btn-outline-dark">
                                        <i className="fa fa-user-plus pr-1"></i> Aanmelden
                                    </button>
                                </span>
                            </>}
                            <span className="nav-item">
                                {currentUser && <span className="nav-link pb-1 pl-0">
                                    <button onClick={this.signOut} className="btn btn-sm btn-secondary-dark border-secondary">
                                        <i className="fa fa-sign-out-alt"></i> Uitloggen
                                    </button>
                                </span>}
                                    {!currentUser && <span className="nav-link pr-2 pl-0 mb-1">
                                <button onClick={() => this.openLogin()} className="btn btn-sm btn-outline-primary">
                                        <i className="fa fa-sign-in-alt pr-1"></i> Inloggen
                                    </button>
                                    </span>}
                            </span>
                        </div>
                        </div>
                    </>}
                </div>  
            </>);
    }
}

export default withRouter(connect(
    state => {
        return {
            currentUser: state.authReducer.currentUser,
            unreadMessages: state.authReducer.unreadMessages,
            authLoading: state.authReducer.authLoading
        }
    }
)(UserButtons));