import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import Tooltip from '../../tooltip/Tooltip';
import { POST } from '../../../scripts/api';

class StatusItem extends Component {

    state = {
        isOpen: false
    }

    setDefault = statusId => {
        POST('status/set-default',
            { statusId },
            statusList => this.props.dispatch({
                type: 'SET_STATUS_LIST',
                statusList
            }),
            error => console.error(error)
        );
    }

    getStyleFromProps = status => {
        return {
            borderColor: status.color,
            color: status.color,
            minWidth: '100px'
        }
    }

    setStatusToDelete = () => {
        const { status } = this.props;
        this.props.dispatch({
            type: 'SET_STATUS_TO_DELETE',
            statusId: status.id
        })
    }

    setStatusToEdit = () => {
        const { status } = this.props;
        this.props.dispatch({
            type: 'SET_STATUS_TO_EDIT',
            statusId: status.id
        })
    }

    render() {

        const { status, noRemove } = this.props
        const { isOpen } = this.state

        return (
            <li className="list-group-item">
                <div className="row small">
                    <div className="col-10">
                        <button className="btn btn-sm btn-outline-secondary disabled mr-3" style={this.getStyleFromProps(status)}><i className={status.symbol}></i></button>
                        <strong>{status.name}</strong>
                        {!!status.isDefault && <>
                            <Tooltip text={"Dit is de standaardstatus. Deze wordt standaard aan elk nieuw ticket gegeven tenzij anders aangegeven."}>
                                <i className="ml-3 mr-3 far fa-lg text-success fa-check-circle"></i>
                            </Tooltip>
                        </>}
                    </div>
                    <div className="col-2">
                        <button onClick={() => this.setState({isOpen: !isOpen})} className="btn btn-sm btn-light float-right">
                            <i className="fa fa-chevron-down"></i>
                        </button>
                    </div>
                </div>
                <Collapse in={isOpen}>
                    <div className="row small">
                        <div className="col-sm-6 pt-2">
                            <label>Omschrijving</label>
                            <p>
                                <i>{status.description}</i>
                            </p>
                        </div>
                        <div className="col-sm-3 pt-2">
                            <label>Kleur</label>
                            <p>
                                <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={status.color}>
                                    <button style={{pointerEvents: 'none'}} className="btn btn-sm btn-outline-secondary">
                                        <i style={this.getStyleFromProps(status)} className='fa fa-square'></i>
                                    </button>
                                </span>
                            </p>
                        </div>
                        <div className="col-sm-3 pt-2">
                            <label>Symbool</label>
                            <p>
                                <button disabled className="btn btn-sm btn-outline-secondary">
                                    <i style={this.getStyleFromProps(status)} className={status.symbol + ' fa-md text-dark'}></i>
                                </button>
                            </p>
                        </div>
                        <div className="col-sm-12">
                        <hr/>
                            <button disabled={noRemove} onClick={this.setStatusToDelete} className="float-right btn btn-danger btn-sm"><i className="fa fa-trash"></i></button>
                            <button onClick={this.setStatusToEdit} className="float-right btn btn-warning btn-sm mr-2"><i className="fa fa-edit"></i></button>
                            <span className="float-right mr-2">
                                {!!status.isDefault && 
                                <Tooltip  text={"Dit is de standaard status"} >
                                    <button className="float-right btn btn-sm btn-outline-danger">
                                        <i className="mr-1 ml-1 fa fa-times"></i>
                                    </button>
                                </Tooltip>}
                                {!status.isDefault && 
                                <Tooltip text={"Instellen als standaard"} onClick={() => this.setDefault(status.id)}>
                                    <button  className="float-right btn btn-sm btn-outline-success">
                                        <i className="fa fa-check"></i>
                                    </button>
                                </Tooltip>}
                            </span>
                        </div>
                    </div>
                </Collapse>
            </li>
        );
    }
}

export default connect(
    state => {
        return {
            statusIdToDelete: state.statusReducer.statusIdToDelete
        }
    }
)(StatusItem);