import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import { generate24HoursArray } from '../../../scripts/timeHelper';
import './AdminSettings.css';
import TooltipItem from '../../tooltip/Tooltip';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { POST } from '../../../scripts/api';
import { connect } from 'react-redux';

const AddTimeModal = props => {

	const [errors, setErrors] = useState({
		day: null,
		date: null,
		from: null,
		to: null,
	})

	const [values, setvalues] = useState({
		day: '',
		defaultDay: '',
		date: null,
		from: 0,
		to: 0,
		isClosed: 0,
	})

	const { close, reloadDays, openingTimes, dispatch } = props;

	const { day, defaultDay, date, from, to, isClosed } = values

	const timeArr = generate24HoursArray();

	const CustomInput = ({ value, onClick }) => (
		<button type="button" className="btn btn-sm btn-primary" onClick={onClick}>
			<i className="fa fa-clock pr-2"></i> {!!date ? moment(value).format("dddd, D MMMM YYYY") : "Selecteer een datum"}
		</button>
	);

	const setDate = newDate => {
		setvalues({
			...values,
			date: newDate
		})
	}

	const validateContent = () => {
		const newErrors = { ...errors}
		newErrors.day = !day.length ? "Voer een geldige dagnaam in" : null;
		newErrors.date = !date ? "Voer een geldige datum in" : null;
		setErrors({...newErrors})
		return !newErrors.day && !newErrors.date
	}

	const updateTimes = id => {

		console.log(openingTimes)

		const newTimes = [...openingTimes ] 

		newTimes.push({
			id,
			...values
		})

		dispatch({
			type: 'SET_TIMES',
			times: newTimes
		})
	}

	const saveChanges = () => {
		if(validateContent()) {
			POST('times/add-opening-times', {
				...values
			},
				newId => {
					// Time updated!
					updateTimes(newId);
					reloadDays();
					close()

				},
				error => console.log(error))
		}
	
	}

	console.log(openingTimes)

	return (
		<Modal show={true} onHide={close}>
			<Modal.Body>
				<h5>Openingstijd toevoegen</h5>
				<hr />
				<form>
					<div className="form-group mb-1">
						<label className="small" htmlFor="register_name"><strong>Dagnaam</strong></label>
						<div className="input-group input-group-sm">
							<div className="input-group-prepend">
								<span className="input-group-text"><i className="fa fa-clock"></i></span>
							</div>
							<input
								value={day}
								onChange={e => setvalues({ ...values, day: e.target.value })}
								type="text"
								className={"form-control form-control-sm " + (errors.day ? "is-invalid" : (day.length > 0 ? "is-valid" : ""))}
								placeholder="Voer een dagnaam in"
							/>
							{errors.day && <div className="invalid-feedback">
								{errors.day}
							</div>}
						</div>
					</div>
					{!isClosed && <>
						<label className="small" htmlFor="register_name"><strong>Tijden</strong></label>
						<div className="row">
							<div className="col-md-2">
								<TooltipItem text={`Open vanaf ${timeArr[from]}`}>
									{timeArr[from]}
								</TooltipItem>
							</div>
							<div className="col-md-8">
								<ReactBootstrapSlider
									disabled={!!isClosed ? "disabled" : ""}
									change={e => setvalues(values => ({ ...values, from: e.target.value[0], to: e.target.value[1] }))}
									value={[from, to]}
									slideStop={e => setvalues(values => ({ ...values, from: e.target.value[0], to: e.target.value[1] }))}
									step={1}
									max={95}
									min={0}
									orientation="horizontal"
								/>
							</div>
							<div className="col-md-2">
								<TooltipItem text={`Open tot ${timeArr[to]}`}>
									{timeArr[to]}
								</TooltipItem>
							</div>
						</div>
					</>}
					<div className="mt-4 custom-control custom-checkbox">
						<input id="closedUnit" checked={isClosed} onChange={e => setvalues(values => ({ ...values, isClosed: +!values.isClosed }))} type="checkbox" className="custom-control-input" />
						<label htmlFor="closedUnit" className="custom-control-label small">Gesloten</label>
					</div>
					{!defaultDay && <>
						<label className="small" htmlFor="register_name"><strong>Datum</strong></label>
						<div>
							<DatePicker
								selected={date ? new Date(date) : new Date()}
								onChange={date => setDate(date)}
								customInput={<CustomInput />}
								minDate={new Date()}
							/>
							{errors.date && <div className="feedback text-danger">
								<small>{errors.date}</small>
							</div>}
						</div>
					</>}
					<hr />
					<button type="button" onClick={() => saveChanges()} className="pull-right btn btn-sm btn-outline-primary float-right">
						<i className="fa fa-save"></i> Opslaan
				</button>
				</form>
			</Modal.Body>
		</Modal>
	)
}


export default connect(
	state => {
		return {
			openingTimes: state.pageReducer.openingTimes
		}
	}
)(AddTimeModal);