import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Page from './../page/Page';
import Profile from './../profile/Profile';
import Tickets from './../tickets/Tickets';
import TicketDetails from '../ticketdetails/TicketDetails';
import Admin from './../admin/Admin';
import Home from './../home/Home';
import CreateTicket from './../tickets/create/CreateTicket';
import CreatePage from './../admin/pages/CreatePage';
import EditPage from './../admin/pages/EditPage';
import AdminPages from '../admin/pages/AdminPages';
import AdminStatusses from '../admin/status/AdminStatusses';
import AdminUsers from '../admin/users/AdminUsers';
import AdminSettings from '../admin/settings/AdminSettings';
import MailUserSettings from '../admin/settings/MailUserSettings';
import './Routes.css';

class Routes extends Component {
    
    render() {
        return (
            <Route render={({location}) => {
                return (
                <TransitionGroup>
                    <CSSTransition
                        key={location.key}
                        timeout={250}
                        classNames={"fade"}>
                        <Switch location={location}>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/tickets" component={Tickets} />
                            <Route exact path="/tickets/create" component={CreateTicket} />
                            <Route exact path="/admin" component={Admin} />
                            <Route exact path="/admin/pages/create" component={CreatePage} />
                            <Route exact path="/admin/pages/edit/:id" component={EditPage} />
                            <Route exact path="/admin/pages" component={AdminPages} />
                            <Route exact path="/admin/ticket-status" component={AdminStatusses} />
                            <Route exact path="/admin/settings" component={AdminSettings} />
                            <Route exact path="/admin/mailing" component={MailUserSettings} />
                            <Route exact path="/admin/users" component={AdminUsers} />
                            <Route exact path="/ticket-details/:id" component={TicketDetails} />
                            <Route exact path="/admin" component={Admin} />
                            <Route exact path="/profile" component={Profile} />
                            <Route component={Page} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
                )
            }} />
        );
    }
}

export default Routes;