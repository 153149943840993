import React, { Component } from 'react';
import AdminItem from './AdminItem';

class Admin extends Component {

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <h3><i className="fa fa-user-cog"></i> Administrator Paneel</h3>
                    <hr />
                    <div className="list-group small mt-4">
                        <AdminItem 
                            to={'/admin/pages'} 
                            title="Pagina's" 
                            content="Pagina's aanpassen, toevoegen en verwijderen"
                        />
                        <AdminItem 
                            to={'/admin/ticket-status'} 
                            title="Ticketstatussen" 
                            content="Ticketstatussen toevoegen, aanpassen en verwijderen"
                        />
                        <AdminItem 
                            to={'/admin/users'} 
                            title="Gebruikers" 
                            content="Rollen toewijzen, gebruikers deactiveren"
                        />
                        <AdminItem 
                            to={'/admin/mailing'} 
                            title="Mailing lijst" 
                            content="Alle e-mailadressen ingeschreven voor de nieuwsbrief"
                        />
                        <AdminItem 
                            to={'/admin/settings'} 
                            title="Overige Instellingen" 
                            content="Wijzig openingstijden en contactgegevens"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Admin;