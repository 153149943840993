import React, { Component } from 'react';
import './Footer.css';
import { isValidEmail } from '../../scripts/formValidator';
import { POST } from '../../scripts/api';
import { connect } from 'react-redux';
import { removeFromLocalStorage } from '../../scripts/localStorage';
import { withRouter } from 'react-router-dom';

class Footer extends Component {

    state = {
        signupEmail: '',
        signupEmailError: null,
        signupSuccess: false
    }

    setSignupEmail = e => {
        this.setState({signupEmail: e.target.value})
    }

    attemptSignUp = () => {
        let emailError = null;
        if(isValidEmail(this.state.signupEmail)) {
            this.signUp(this.state.signupEmail);
        } else {
            emailError = true;
        }
        this.setState({signupEmailError: emailError})
    }

    signUp = email => {
        POST('newsletter/signup',
            { email },
            () => {
                this.setState({
                    signupSuccess: true, 
                    signupEmail: ''
                })
                this.reset()
            },
            () => this.setState({signupEmailError: true})
        )
    }

    reset = () => {
        setTimeout(() => {
            this.setState({
                signupSuccess: false
            })
        }, 5000)
    }

    openLogin = () => {
        this.props.dispatch({
            type: 'TOGGLE_LOGIN'
        })
    }

    openRegister = () => {
        this.props.dispatch({
            type: 'TOGGLE_REGISTER'
        })
    }

    signOut = () => {
        this.props.dispatch({
            type: 'SET_CURRENT_USER',
            user: null
        })
        this.props.history.push('/');
        removeFromLocalStorage('CompuToken');
    }

    render() {

        const { signupEmail, signupSuccess, signupEmailError } = this.state;

        const { currentUser, pages } = this.props;

        return (
            <div className="footer">
                <div className="footer-container container-fluid">
                    <div className="row pr-3 pl-3">
                        {!!pages && pages.filter(page => !page.parentPageId) && <div className="col-sm-3 col-md-3 col-xs-12 small text-secondary">
                            <h6 className="text-warning">Pagina's</h6>
                            <hr className="border-secondary" />
                            {pages.filter(page => !page.parentPageId).map(page => (
                                <a href={`/${page.pageUrl}`}><p>{page.title}</p></a>
                            ))}
                        </div>} 
                        <div className="col-sm-3 col-md-3 col-xs-12 small text-secondary">
                            <h6 className="text-warning">Hulp</h6>
                            <hr className="border-secondary"/>
                            <a href="/tickets"><p>Ticketcentrum</p></a>
                            <a href="/tickets"><p>Mijn Tickets</p></a>
                        </div>
                    
                        <div className="col-sm-3 col-md-3 col-xs-12 small text-secondary">
                            <h6 className="text-warning">Account</h6>
                            <hr className="border-secondary"/>
                            {!currentUser && <>
                                <a href="#" onClick={() => this.openLogin()}><p>Inloggen</p></a>
                                <a href="#" onClick={() => this.openRegister()}><p>Aanmelden</p></a>
                            </>}
                            {!!currentUser && <>
                                <a href="#" onClick={() => this.signOut()}><p>Uitloggen</p></a>
                            </>}
                        </div>
                        
                        <div className="col-md-3 col-sm-6 col-sm-offset-3 col-xs-12 small text-secondary">
                            <h6 className="text-warning pb-2">Schrijf je in voor de nieuwsbrief</h6>
                            <div className="input-group input-group-sm text-dark bg-dark">

                                <input value={signupEmail} onChange={this.setSignupEmail} type="text" className={"form-control bg-dark border-secondary text-muted" + (!signupEmail.length ? "" : (isValidEmail(signupEmail) ? " is-valid" : " is-invalid"))} placeholder="Jouw E-mailadres" />
                                <div className="input-group-append">
                                    {!signupSuccess && <>
                                        <button onClick={this.attemptSignUp} className="btn btn-sm btn-outline-secondary"><i className="fa fa-user-plus"></i> <strong>Inschrijven</strong></button>
                                    </>}
                                    {signupSuccess && <>
                                        <button disabled className="btn btn-sm btn-outline-secondary border-success text-light"><i className="fa fa-check-double text-success"></i> <strong>Ingeschreven!</strong></button>
                                    </>}
                                </div>
                            </div>
                            {signupEmailError && <span className="small text-danger">Vul een geldig E-mail adres in</span>}
                            <br />
                            <strong className="text-secondary cc-title cc-font"><h5>Compucorner</h5></strong>
                            <p className="text-secondary">Draadbaan 1 2352 BM Leiderdorp</p>     
                        </div>
                                
                    </div>
                            
                </div>
                <div className="footer-bottom text-center">
                    <p className="text-secondary"><small> Compucorner {new Date().getFullYear()}, Alle rechten voorbehouden.</small> </p>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            pages: state.pageReducer.pages,
            currentUser: state.authReducer.currentUser
        }
    }
)(Footer));