import React, { Component } from 'react';

class TicketUserButton extends Component {

    getUserRoleButtonClassName = roleName => {
        if(roleName === 'admin') {
            return 'badge user-icon badge-pill badge-danger'
        }
        return 'badge user-icon badge-pill badge-primary'
    }

    getUserRoleIconClassName = roleName => {
        if(roleName === 'admin') {
            return 'fa fa-lg fa-user-shield'
        }
        return 'fa fa-lg fa-user-tag'
    }

    render() {

        const { user, addText, preText } = this.props

        return (
            <div>
                {user && <>
                    <span className="text-left">
                        {preText ? preText : ''}
                        <span disabled className={this.getUserRoleButtonClassName(user.roleName) + ' mr-1 ml-1'}>
                            <i className={this.getUserRoleIconClassName(user.roleName)}></i> 
                        </span>  {user.name} {addText ? addText : ''}
                    </span>
                </>}
                {!user && <i className="fa fa-times text-danger"></i>}
            </div>
        );
    }
}

export default TicketUserButton;