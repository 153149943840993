import React, { Component } from 'react';
import { GET } from '../../../scripts/api';
import NoAccessBlock from '../../helpers/NoAccessBlock';
import PageList from './PageList';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PrevPageButton from '../../buttons/PrevPageButton';
import { sortByProperty } from '../../../scripts/arrayHelper';


class AdminPages extends Component {

    state = {
        error: null
    }

    componentDidMount() {
        this.getPageList();
    }

    getPageList = () => {
        GET('admin/pages',
            {},
            pageList => this.props.dispatch({
                type: 'SET_PAGE_LIST',
                pageList: sortByProperty('pageOrder', pageList)
            }),
            error => {
                console.error(error);
                this.setState({error: true});
            }
        )
    }
    render() {

        const { error } = this.state;
        const { pageList } = this.props;

        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <h3>
                                <i className="fa-file far"></i> Pagina's Aanpassen  ({pageList ? pageList.length : 0})
                            </h3>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                            <Link to={"/admin/pages/create"}>
                                <button className="btn btn-sm btn-success float-right my-1 ml-1 mr-2">
                                    <i className="fa fa-plus"></i> Nieuwe pagina
                                </button>
                            </Link>
                            <div className="my-1 mx-1">
                                <PrevPageButton to={'/admin'} />
                            </div>
                        </div>
                    </div>
                    <hr className="mt-0 mb-3" />
                    {!pageList && !error && <>
                        <div className="loading-container">
                            <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                        </div>
                    </>}
                    {pageList && !error && <PageList pageList={pageList} />}
                    {!pageList && error && <NoAccessBlock />}
                </div>
            </div>
        );
    }
}

export default connect(
    state => {
        return {
            pageList: state.pageReducer.pageList
        }
    }
)(AdminPages);