import React, { Component } from 'react';

class TableWrapper extends Component {
    render() {
        return (
            <table className="table table-bordered table-responsive small mt-3">
                {this.props.children}
            </table>
        );
    }
}

export default TableWrapper;