import React, { Component } from 'react';

class HiddenMenuContainer extends Component {
    render() {

        const { setHidden, isHidden } = this.props

        return (
            <>
                <div className="mt-4 custom-control custom-checkbox">
                    <input checked={isHidden} onChange={setHidden} type="checkbox" className="custom-control-input" id="adminOnly" />
                    <label className="custom-control-label small" htmlFor="adminOnly">Verborgen op menu</label>
                </div>
            </>
        );
    }
}

export default HiddenMenuContainer;