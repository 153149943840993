import React, { Component } from 'react';

class NoAccessBlock extends Component {
    render() {
        return (
            <div className="not-found-container">
                <h2 className="text-secondary font-weight-light"><i className="fa fa-lock"></i><span> Je hebt geen toegang tot deze pagina.</span></h2>
            </div>
        );
    }
}

export default NoAccessBlock;