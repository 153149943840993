import React from 'react';
import Workbook from 'react-excel-workbook'


const DownloadButton = () => <button className="btn btn-sm btn-success" >
	<i className="fa fa-download"></i> Exporteren als .xls
</button>

const ExportEmailExcel = ({ data }) => {

	const generateFileName = () => {
		return `compucorner_inschrijvingen_${new Date().getTime()}.xlsx`
	}

	const formatData = () => {
		return data.map(item => item.email)
	}

	console.log(data)

	return (
		<div className="float-right">
			<Workbook filename={generateFileName()} data={data} element={DownloadButton()}>
				<Workbook.Sheet data={data} name="Compucorner Inschrijvingen">
					<Workbook.Column label="E-mail" value={row => row.email} />
					<Workbook.Column label="Inschrijfdatum" value={row => row.createdAt} />
				</Workbook.Sheet>
			</Workbook>
		</div>
	
	)
};

export default ExportEmailExcel;