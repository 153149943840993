import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import NameInput from './inputs/NameInput';
import EmailInput from './inputs/EmailInput';
import PasswordInput from './inputs/PasswordInput';
import RoleInput from './inputs/RoleInput';
import BanInput from './inputs/BanInput';
import { isValidEmail, isSafePassword } from '../../../scripts/formValidator';
import { hasErrors } from '../../../scripts/errorHelper';
import { POST } from '../../../scripts/api';

class UserEditModal extends Component {

    state = {
        selectedUser: null,
        errors: {
            name: null,
            email: null,
            password: null,
        }
    }

    attemptEditUser = () => {
        if(this.validateUser()) {
            this.editUser()
        }
    }

    
    validateUser = () => {
        const { selectedUser } = this.state;
        const { name, email, newPassword, roleName, isBanned } = selectedUser;
        let errors = { ...this.state.errors }

        errors.email = !isValidEmail(email) ? 'Voer een geldig email adres in.' : null
        errors.name = name.length < 3 ? 'Voer een volledige naam in.' : null
        errors.password = (newPassword && !isSafePassword(newPassword)) ? 'Voer een correct wachtwoord in (minimaal 6 karakters).' : null

        this.setState({ errors })
        return !hasErrors(errors);
    }

    editUser = () => {
        const { selectedUser } = this.state;
        const { id, name, email, newPassword, roleName, isBanned } = selectedUser;
        POST('admin/user/edit',
            {
                id,
                name,
                email,
                newPassword,
                roleName,
                isBanned
            },
            userList => {
                this.props.dispatch({
                    type: 'SET_USER_LIST',
                    userList
                })
                this.close();
            },
            error => console.error(error)
        );
    }

    close = () => {
        this.setState({selectedUser: null})
        this.props.dispatch({
            type: 'SET_USER_TO_EDIT',
            userId: null
        })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.userIdToEdit && nextProps.userList) {
            const { userIdToEdit, userList } = nextProps
            this.setState({
                selectedUser: userList ? userList.find(user => user.id === userIdToEdit) : null
            })
        }
    }

    setRole = roleName => {
        this.setState({selectedUser: {
            ...this.state.selectedUser,
            roleName
        }})
    }

    setName = name => {
        this.setState({selectedUser: {
            ...this.state.selectedUser,
            name
        }})
    }

    setEmail = email => {
        this.setState({selectedUser: {
            ...this.state.selectedUser,
            email
        }})
    }

    setPassword = newPassword => {
        this.setState({selectedUser: {
            ...this.state.selectedUser,
            newPassword
        }})
    }

    setBanned = isBanned => {
        this.setState({selectedUser: {
            ...this.state.selectedUser,
            isBanned: isBanned ? 1 : 0
        }})
    }

    render() {

        const { selectedUser, errors } = this.state

        return (
            <Modal show={!!selectedUser} onHide={this.close}>
                <Modal.Header>
                    <Modal.Title>Gebruiker wijzigen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUser && <>
                        <NameInput setName={this.setName} name={selectedUser.name} error={errors.name} />
                        <EmailInput setEmail={this.setEmail} email={selectedUser.email} error={errors.email}  />
                        <PasswordInput setPassword={this.setPassword} password={selectedUser.newPassword ? selectedUser.newPassword : ''} error={errors.password}  />
                        <RoleInput setRole={this.setRole} role={selectedUser.roleName} error={errors.role}  />
                        <BanInput setBanned={this.setBanned} isBanned={!!selectedUser.isBanned} />
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.attemptEditUser} className="btn btn-sm btn-outline-success"><i className="fa fa-save"></i> Wijzigingen opslaan</button>
                    <button onClick={this.close} className="btn btn-sm btn-outline-secondary"><i className="fa fa-times"></i> Sluiten</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(
    state => {
        return {
            userIdToEdit: state.userReducer.userIdToEdit,
            userList: state.userReducer.userList
        }
    }
)(UserEditModal);