import React, { Component } from 'react';

class HomepageContainer extends Component {
    render() {

        const { toggleHomepage, isHomepage } = this.props

        return (
            <>
                <div className="mt-4 custom-control custom-checkbox">
                    <input checked={isHomepage} onChange={toggleHomepage} type="checkbox" className="custom-control-input" id="isHomepage" />
                    <label className="custom-control-label small" htmlFor="isHomepage">Hoofdpagina</label>
                </div>
            </>
        );
    }
}

export default HomepageContainer;