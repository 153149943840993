import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 

class ParentPageContainer extends Component {

	removeOwnChildren = (pages, parentPageId) => {
		for(let index = 0; index < pages.length; index++) {
			if(pages[index].parentPageId === parentPageId) {
				const pageToCheck = pages[index];
				pages = pages.filter(page => page.id !== pageToCheck.id);
				return this.removeOwnChildren(pages, pageToCheck.id);
			}
		}
		return pages.filter(page => page.id != this.props.match.params.id)
	}

	render() {

		const { pages, selectedPageId } = this.props;

		return (
			<>
				{pages && <>
					<label className="small mt-3"><strong>Sub pagina van</strong></label>
					<div className="input-group input-group-sm">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<i className="fa fa-file-alt mx-2"></i>
							</span>
						</div>
						<select onChange={e => this.props.setParentPage(e.target.value)} defaultValue={selectedPageId || 0} value={selectedPageId || 0} className="form-control">
							<option value="0">Geen</option>
							{this.removeOwnChildren(pages, parseInt(this.props.match.params.id, 10)).map(page => {
								return (
									<option key={page.id} value={page.id}>{page.title}</option>
								)
							})}
						</select>
					</div>
				</>}
				{!pages && <div className="col-12 text-center pt-2">
					<div className="spinner-border text-secondary spinner-border-sm mt-3 mb-3" role="status"></div>
				</div>}
			</>
		);
	}
}

export default withRouter(connect(
	state => {
		return {
			pages: state.pageReducer.pages
		}
	}
)(ParentPageContainer));