import React, { Component } from 'react';
import { isAdmin } from '../../scripts/roleHelper';
import { connect } from 'react-redux';
import TicketUserButton from '../buttons/TicketUserButton';
import { formatDate } from '../../scripts/dateFormatter';
import ReparatieStatusButton from '../buttons/ReparatieStatusButton';
import { withRouter } from 'react-router-dom';
import Tooltip from '../tooltip/Tooltip';
import { Collapse } from 'react-bootstrap';

class TicketMessage extends Component {

    state = {
        hideChildren: false
    }

	gradient = ["#007bf6","#0077ec","#0072e3","#006dd9","#0068d0","#0064c6","#005fbd","#005ab3","#0055aa","#0051a0","#004c97","#00478d","#004284","#003e7a","#003971"]

    setMessageToDelete = () => {
        const { message } = this.props;
        this.props.dispatch({
        type: 'SET_MESSAGE_TO_DELETE',
            messageId: message.id,
            ticketId: message.ticketId
        });
    }

    toggleHideChildren = () => this.setState({hideChildren: !this.state.hideChildren})

    getStyleByLevel = () => {
        return {
            borderLeft: `1px solid rgba(0,0,0,.125)`,
			left: `20px`,
            width: `calc(100% - 20px)`,
            position: 'relative',
        }
    }

    getBorderColor = () => {
        const { level } = this.props
        if(!level) {
            return { borderRadius: '0px', borderLeft: 'none' }
        }
        return {
            borderLeft: `5px solid ${this.gradient[level % this.gradient.length]}`,
            width: `calc(100% - 20px)`,
            borderRadius: '0px'
        }
    }

    render() {

        const { message, currentUser, level } = this.props;
        const { hideChildren } = this.state

        // content: "Nam ultrices, libero non mattis pulvinar, nulla pede ullamcorper augue, a suscipit nulla elit ac nulla. Sed vel enim sit amet nunc viverra dapibus. Nulla suscipit ligula in lacus. Curabitur at ipsum ac tellus semper interdum. Mauris ullamcorper purus sit amet nulla. Quisque arcu libero, rutrum ac, lobortis vel, dapibus at, diam. Nam tristique tortor eu pede."
        // createdAt: "2018-05-10 07:07:58"
        // id: 614
        // isDeleted: 1
        // ticketId: 112
        // title: "Latlux"
        // updatedAt: null
        // userCreatedId: 3

        return (
            <>
            <div className="card small" style={this.getBorderColor()} >
                {!!message.isDeleted && <span className="text-center bg-light py-3 text-secondary">
                    <i><i className="fa fa-trash"></i> Dit bericht is verwijderd</i>
                    {/* {!!isAdmin(currentUser) && <span className="float-right mx-3 px-1">
                        <button onClick={() => this.props.undelete(message.id)} className="btn btn-sm btn-outline-success">
                            <i className="fa fa-recycle"></i>
                        </button>
                    </span>} */}
                </span>}
                {!message.isDeleted && 
                <>
                    <div className="card-header">
                        <span className="float-right"><i className="fa fa-clock"></i> {formatDate(message.createdAt)}</span>
                        {message.user && <TicketUserButton user={message.user} addText={!!message.parentMessageId ? "reageerde" : ""} />}
                    </div>
                    <div className="card-body">
                        <h6>{message.title}</h6>
                        <p>{message.content}</p>
                        <hr />
                        <span className="float-right mt-3">
                            {!!message.adminOnly && <>
                                <Tooltip text={"Niet zichtbaar voor gebruikers"}>
                                    <button className="disabled btn btn-sm"><i className="fas fa-eye-slash"></i></button>
                                </Tooltip>
                            </>}
                            <button onClick={() => this.props.reply(message.id)} className="btn btn-sm btn-outline-success"><i className="fa fa-reply"></i> Reageren</button>
                            {isAdmin(currentUser) && <button onClick={this.setMessageToDelete} className="ml-2 btn btn-sm btn-outline-danger"><i className="fa fa-trash"></i></button>}
                        </span>
                        {message.status && <>
                            <span className="pr-3 d-inline">
                                <ReparatieStatusButton preText={<strong className="pr-3">Status aangepast naar</strong>} status={message.status}/>
                            </span>
                        </>}
                        {message.children && <>
                            <Tooltip text={"Reacties in/uitklappen"}>
                                <button onClick={this.toggleHideChildren} className="btn btn-outline-secondary btn-sm mt-3">
                                    <i  className="fa fa-chevron-down"></i>
                                </button>
                            </Tooltip>
                        </>}

                        
                    </div>
                </>}
            </div>
            {message.children && <>
                <Collapse in={!hideChildren}>
                    <div>
                        {message.children.map(subMessage => {
                            return (
                                <div style={this.getStyleByLevel()} key={subMessage.id}>
                                    <TicketMessage dispatch={this.props.dispatch} currentUser={currentUser} level={level + 1} message={subMessage} reply={this.props.reply} />
                                </div>
                            )
                        })}
                    </div>
                </Collapse>
            </>}
            </>
        );
    }
}

export default withRouter(connect(
) (TicketMessage));