import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Navigation.css';
import { GET } from '../../scripts/api';
import { Navbar } from 'react-bootstrap';
import UserButtons from './UserButtons';
import NavItem from './NavItem';
import { getNestedChildren } from '../../scripts/arrayHelper';
import { generate24HoursArray } from '../../scripts/timeHelper';
import { useState } from 'react';
import { useEffect } from 'react';
import TooltipItem from '../tooltip/Tooltip';
import TimesModal from './TimesModal';

const Navigation = props => {
    
    const { pages, dispatch, openingTimes } = props;

    const [ error, setError ] = useState(false);

    const [currentTime, setCurrentTime ] = useState(null);

    useEffect(() => {
        getPages();

        getOpeningTimes();
    }, [])

    useEffect(() => {
        getOpeningTimes();
    }, [openingTimes])

    const getPages = () => {
        GET('pages/links',
            {},
            pages => dispatch({
                type: 'SET_PAGES',
                pages
            }),
            () => setError(true)

        )
    }

    const toggleTimesModal = () => {
        dispatch({
            type: 'TOGGLE_TIMES',
        })
    }

    const getOpeningTimes = () => {
        GET('times/current-opening-times', {},
            openingTime => setCurrentTime(openingTime),
            () => setError(true)
        )
    }

    const getScreenWidth = () => {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }

    const isMobile = () => {
        return getScreenWidth() < 990;
    }

    return (
        <>
            <div className="header">
                <div className="header-options info-bar pr-4 pl-4">
                    <UserButtons />
                </div>
                <hr className="mb-0 mt-0" />
                <div className="row info-bar text-center small pt-3">
                    <div className="col-md-6 col-lg-3 mx-auto col-xs-12 mb-3">
                        <span className="text-secondary">
                            <i className="far fa-question-circle"></i> Support: (+31)<strong className="ml-1 text-dark"> 071-5421584</strong>
                        </span>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xs-12 mx-auto border-left border-right mb-3">
                        <span className="text-secondary">
                            <i className="fas fa-at"></i> E-mail: <strong className="ml-1 text-dark">info@compucorner.nl</strong>
                        </span>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xs-12 mx-auto mb-3 border-right">
                        <TooltipItem text="Klik voor extra openingstijden">
                        <div onClick={() => toggleTimesModal()}>
                        {!currentTime && "-"}
                        {currentTime && <span className="text-secondary">
                            {!!currentTime.isClosed && <>
                                <i className="far fa-clock"></i> <strong>Vandaag gesloten</strong> ({currentTime.day})
                                </>}
                            {!currentTime.isClosed && <>
                                <i className="far fa-clock"></i> Geopend van <strong>{generate24HoursArray()[currentTime.from]}</strong> tot <strong>{generate24HoursArray()[currentTime.to]} </strong>
                            </>}
                        </span>}
                            </div>
                        </TooltipItem>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xs-12 mx-auto mb-3">
                        <span>
                            <a target="_blank" rel="noopener noreferrer" className="text-dark" href="https://twitter.com/compucorner_vof"><i className="fab fa-lg fa-twitter pr-2 pl-2 "></i></a>
                            <a target="_blank" rel="noopener noreferrer" className="text-dark" href="https://www.facebook.com/pages/category/Community/Compucorner-1431992547042194/"><i className="fab fa-lg fa-facebook-square pr-2 pl-2"></i></a>
                        </span>
                    </div>
                </div>
                <hr className="mb-0 mt-0" />
                <Navbar bg="light" expand="lg">
                    <span className="info-bar w-100 pt-1 pb-1">

                        <div className="pl-4"><Navbar.Toggle aria-controls="basic-navbar-nav" /></div>
                        <Navbar.Collapse id="basic-navbar-nav" className="ml-3 mr-3">
                            {!pages && !error && <div className="spinner-border text-secondary spinner-border-sm mt-1 mr-3 ml-3 mt-3 mb-3" role="status"></div>}
                            {!pages && error && <>
                                <div className="pl-3 pt-3 pb-3">
                                    <i className="fa fa-exclamation-triangle text-secondary"></i>
                                    <span className="text-secondary ml-2">Er is iets mis gegaan. Herlaad de pagina.</span>
                                </div>
                            </>}
                            {pages && <ul className="nav mr-auto navbar-nav">
                                {getNestedChildren(pages, null, 'parentPageId').map(page => {
                                    return (<span key={page.id}>
                                        <NavItem
                                            path={props.history.location.pathname}
                                            page={page}
                                            level={0}
                                        />
                                    </span>)

                                })}
                            </ul>}
                            {pages && <a href={window.location.origin + '/teamviewer.exe'} ><button className="btn btn-primary btn-lg pulsating pr-4">
                                <i className="fa fa-download ml-2 mr-3"></i> <>Hulp op afstand</>
                            </button></a>}
                        </Navbar.Collapse>
                    </span>
                </Navbar>
                <TimesModal />
            </div>
        </>
    );
}

export default withRouter(connect(
    state => {
        return {
            currentUser: state.authReducer.currentUser,
            pages: state.pageReducer.pages,
            openingTimes: state.pageReducer.openingTimes,
            showTimes: state.pageReducer.showTimes,
        }
    }
) (Navigation));