import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DELETE, POST } from '../../../scripts/api';

class DeletePageModal extends Component {

    close = () => {
        this.props.dispatch({
            type: 'SET_DELETE_PAGE',
            pageId: null
        })
    }

    deletePage = () => {
        const { pageIdToDelete } = this.props;
        POST('page/remove',
            {
                pageId: pageIdToDelete
            },
            data => {
                this.props.dispatch({
                    type: 'SET_PAGE_LIST',
                    pageList: data.pageList
                })
                this.props.dispatch({
                    type: 'SET_PAGES',
                    pages: data.pages
                })
                this.close()
            },
            error => console.error(error)
        );
    }

    render() {

        const { pageIdToDelete } = this.props;

        return (
            <Modal className="small" show={!!pageIdToDelete} onHide={this.close}>
                <Modal.Header>
                    <Modal.Title>Aanmelding verwijderen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je zeker dat je deze pagina wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.deletePage} className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"></i> Pagina verwijderen</button>
                    <button onClick={this.close} className="btn btn-sm btn-outline-secondary"><i className="fa fa-times"></i> Sluiten</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            pageIdToDelete: state.pageReducer.pageIdToDelete
        }
    }
) (DeletePageModal));