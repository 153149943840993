
const initialState = {
    tickets: null,
    ticketIdToDelete: null
}

export default function ticketReducer(state = initialState, action) {
    switch(action.type) {
        case 'SET_TICKETS': {
            return {
                ...state,
                tickets: action.tickets
            }
        }
        case 'SET_TICKET_TO_DELETE': {
            return {
                ...state,
                ticketIdToDelete: action.ticketId
            }
        }
        case 'SET_MESSAGE_TO_DELETE': {
            return {
                ...state,
                messageIdToDelete: action.messageId
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}