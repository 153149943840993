import React, { Component } from 'react';

class IsCategoryContainer extends Component {
    render() {

        const { toggleCategory, isCategory } = this.props

        return (
            <>
                <div className="mt-4 custom-control custom-checkbox">
                    <input checked={isCategory} onChange={toggleCategory} type="checkbox" className="custom-control-input" id="isCategory" />
                    <label className="custom-control-label small" htmlFor="isCategory">Categorie <i className="fa fa-question"></i> </label>
                </div>
            </>
        );
    }
}

export default IsCategoryContainer;