import React, { Component } from 'react';
import { iconList } from './iconList';
import './IconPicker.css';

class IconPicker extends Component {

    state = {
        selectedIcon: null,
        dropdownOpen: false,
        searchQuery: '',
        iconList: iconList()
    }

    filterBySearchQuery = icons => {
        const { searchQuery } = this.state;

        return icons.filter(icon => {
            const filteredIconName = this.filterFontAwesomeString(icon).toLowerCase();
            return (!searchQuery.length || (filteredIconName.indexOf(searchQuery.toLowerCase()) !== -1))
        });
    }

    enableDropdown = () => {
        this.setState({dropdownOpen: true})

    }

    disableDropdown = () => {
        this.setState({dropdownOpen: false})
    }

    componentDidMount() {
        if(this.props.icon) {
            this.setState({selectedIcon: this.props.icon})
        }
    }

    setIcon = icon => {
        this.setState({selectedIcon: icon, searchQuery: ''})
        this.props.setIcon && this.props.setIcon(icon);
    }

    setSearchQuery = e => {
        this.setState({
            searchQuery: e.target.value,
            selectedIcon: null
        })
    }

    filterFontAwesomeString = iconText => {
        iconText = iconText.replace("fa fa-", "")
        iconText = iconText.replace("fab fa-", "")
        iconText = iconText.replace("fas fa-", "")
        iconText = iconText.replace("far fa-", "")
        iconText = iconText.replace(/-/g, " ")
        return iconText.charAt(0).toUpperCase() + iconText.slice(1)
    }

    clearIcon = () => {
        this.setState({selectedIcon: null, searchQuery: ''})
        this.props.setIcon && this.props.setIcon('');
    }

    render() {

        const { selectedIcon, iconList, searchQuery } = this.state;

        return (
            <div>
                <div className="dropdown pt-3">
                    <label className="small"><strong>Symbool</strong></label>
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <label className="input-group-text" ><i className="mr-2 ml-2 fa fa-list"></i></label>
                        </div>
                        {!selectedIcon && <div className="input-group-prepend">
                            <label className="input-group-text" ><i>(Geen)</i></label>
                        </div>}
                        {selectedIcon && <div className="input-group-prepend">
                            <label style={{pointerEvents: 'none'}} className="pr-3 pl-3 input-group-text border-success text-success" ><i className={selectedIcon}></i></label>
                        </div>}
                        <input onChange={this.setSearchQuery} value={searchQuery} data-toggle="dropdown" type="text" className={"form-control" + (selectedIcon ? ' is-valid' : '')} placeholder="Zoeken naar symbool (Engels)" onFocus={this.enableDropdown} onBlur={this.disableDropdown} />
                        <div className="input-group-append" onClick={this.clearIcon}>
                            <label className="pr-3 pl-3 input-group-text" ><i className="fa fa-times"></i></label>
                        </div>
                        <div className="dropdown-menu icon-menu pt-0 pb-0">
                            {this.filterBySearchQuery(iconList).map((icon) => {
                                return (<div onClick={() => this.setIcon(icon)} className="list-group-item pt-2 pb-2 small icon-item" key={icon} >
                                    <i className={icon + ' mr-3 fa-lg'}></i> {this.filterFontAwesomeString(icon)}
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IconPicker;