import React, { Component } from 'react';
import Tooltip from '../../tooltip/Tooltip';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../scripts/dateFormatter';
import { sortByProperty } from '../../../scripts/arrayHelper';
import TicketUserButton from '../../buttons/TicketUserButton';
import { connect } from 'react-redux';
import { Collapse } from 'react-bootstrap';

class ChildPageItem extends Component {

	state = {
		hideChildren: false
	}

    toggleHideChildren = () => this.setState({hideChildren: !this.state.hideChildren})

	gradient = ["#007bf6","#0077ec","#0072e3","#006dd9","#0068d0","#0064c6","#005fbd","#005ab3","#0055aa","#0051a0","#004c97","#00478d","#004284","#003e7a","#003971"]

	getStyleByLevel = () => {
        return {
            borderLeft: `1px solid rgba(0,0,0,.125)`,
			left: `20px`,
            width: `calc(100% - 20px)`,
            position: 'relative',
        }
    }

    getBorderColor = () => {
        const { level } = this.props
        if(!level) {
            return { borderRadius: '0px', borderLeft: 'none' }
        }
        return {
            borderLeft: `5px solid ${this.gradient[level % this.gradient.length]}`,
            borderRadius: '0px'
        }
    }

	setPageIdToDelete = pageId => {
        this.props.dispatch({
            type: 'SET_DELETE_PAGE',
            pageId
        })
	}

	render() {

		const { page, level, isDragging, isDroppable } = this.props;
		const { hideChildren } = this.state

		return (
			<>
			<li key={page.id} className="list-group-item" style={this.getBorderColor()}>
				<div  className="row small">
					<div className="col-sm-6 col-md-6 py-2">
						{page.children && <>
							<Tooltip text={"Pagina's in/uitklappen"}>
								<button onClick={this.toggleHideChildren} className="btn btn-outline-secondary btn-sm mr-3">
									<i  className="fa fa-chevron-down"></i>
								</button>
							</Tooltip>
						</>}
						<strong> {page.title}</strong>
					</div>
					<div className={"col-sm-12 col-md-6 py-2"} >
						<span className="float-right">
							{!!page.isHomepage && <Tooltip text={"Dit is de hoofdpagina"}>
								<button style={{ pointerEvents: 'none' }} disabled className="btn btn-primary btn-sm mx-1 my-1"><i className="fa fa-home"></i></button>
							</Tooltip>}
							{page.user_created && <Tooltip text={ <TicketUserButton 
									user={page.user_last_edited} 
									addText={<i className="ml-2">({formatDate(page.updatedAt)})</i>} 
									preText={<span><i className="fa fa-user-edit mr-2"></i><strong>Bewerkt</strong> door </span>} 
								/>}>
								<button style={{ pointerEvents: 'none' }} disabled className="btn btn-sm mx-1 my-1 btn-outline-warning">
								<i className="fa fa-user-edit"></i>
								</button>
							</Tooltip>}
							
							{page.user_last_edited && <Tooltip text={<TicketUserButton 
									user={page.user_created} 
									addText={<i className="ml-2">({formatDate(page.createdAt)})</i>} 
									preText={<span><i className="far fa-plus-square mr-2"></i><strong>Aangemaakt</strong> door </span>} 
								/>}>
								<button style={{ pointerEvents: 'none' }} disabled className="btn btn-sm mx-1 my-1 btn-outline-success">
								<i className="fa fa-user-plus"></i>
								</button>
							</Tooltip>}
							{/* {!!page.isCategory && <Tooltip text={"Deze pagina werkt als dropdown voor sub-pagina's"}>
								<button style={{ pointerEvents: 'none' }} disabled className="btn btn-outline-info btn-sm mx-1 my-1"><i className="fa fa-clipboard-list mx-1"></i></button>
							</Tooltip>} */}
						
							{!!page.isHidden && <Tooltip text={"Verborgen op menu"}>
								<button disabled style={{ pointerEvents: 'none' }} className="btn btn-secondary btn-sm mx-1 my-1"><i className="fa fa-eye-slash"></i></button>
							</Tooltip>}
							<Tooltip text={"Pagina aanpassen"}>
								<Link to={'/admin/pages/edit/' + page.id}><button className="btn btn-warning btn-sm mx-1 my-1"><i className="fa fa-edit"></i></button></Link>
							</Tooltip>
							<Tooltip text={"Pagina verwijderen"}>
								<button onClick={() => this.setPageIdToDelete(page.id)} className="btn btn-danger btn-sm mx-1 my-1"><i className="fa fa-trash"></i></button>
							</Tooltip>
						</span>
					</div>                        
				</div>
			</li>
			{page.children && <>
                <Collapse in={!hideChildren}>
                    <div>
                        {sortByProperty('id', page.children).map(subPage => {
							return (<div style={this.getStyleByLevel()} key={subPage.key}>
								<ChildPageItem dispatch={this.props.dispatch} page={subPage} level={level + 1} isDragging={isDragging} isDroppable={isDroppable} />
							</div>)
                        })}
                    </div>
                </Collapse>
            </>}
			</>
		);
	}
}

export default connect() (ChildPageItem);