import React, { Component } from 'react';
import { GET } from '../../scripts/api';
import './Home.css';

class Home extends Component {

    componentDidMount() {
        this.getHomepageData();
    }

    getHomepageData = () => {
        GET('pages/home', 
            {},
            page => {
                let container =  document.getElementById('homepage-content');
                container.innerHTML = page.content
                // const images = container.getElementsByTagName('img');
                // images[0].style.marginBottom = '-100%';
                // images.forEach((image, index) => {
                //     window.$(images[index]).on('load', () => {
                //         images[index].style.maxHeight = image.height + 'px';
                //     })
                // })
            },
            error => {

            })
    }

    render() {
        return (
            <>
            <div className="row mx-0 my-0 py-0 px-0 home-page">
                <div id="homepage-content">
                </div>
                <div className="clearfix"></div>

            </div>
            </>
        );
    }
}

export default Home;