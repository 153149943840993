import React, { Component } from 'react';

class RoleInput extends Component {

    roleTypes = [
        {
            name: 'admin',
            displayText: 'Administrator'
        },
        {
            name: 'user',
            displayText: 'Gebruiker'
        }
    ]

    render() {
        const { role } = this.props;

        const isUser = role === "user";
        const isAdmin = role === "admin";

        return (
            <>
                <label className="small mt-3"><strong>Kies nieuwe rechten</strong></label>
                <div className="btn-group btn-group-sm w-100">
                    <button onClick={() => this.props.setRole("user")} type="button" className={"w-50 btn btn-sm btn-primary" + (isUser ? " active" : "")}>
                        <i className="fa fa-user"></i> Gebruiker {isUser ? <i className="ml-2 fa fa-check text-success"></i> : ""}
                    </button>
                    <button onClick={() => this.props.setRole("admin")} type="button" className={"w-50 btn btn-sm btn-danger" + (isAdmin ? " active" : "")}>
                        <i className="fa fa-user-shield"></i> Administrator {isAdmin ? <i className="ml-2 fa fa-check text-success"></i> : ""}
                    </button>
                </div>
            </>
        );
    }
}

export default RoleInput;