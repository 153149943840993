
const initialState = {
    userIdToEdit: null,
    userIdToBan: null,
    userList: null
}

export default function userReducer(state = initialState, action) {
    switch(action.type) {
        case 'SET_USER_LIST': {
            return {
                ...state,
                userList: action.userList
            }
        }
        case 'SET_USER_TO_BAN': {
            return {
                ...state,
                userIdToBan: action.userId
            }
        }
        case 'SET_USER_TO_EDIT': {
            return {
                ...state,
                userIdToEdit: action.userId
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}