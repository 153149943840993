import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AdminItem extends Component {
    render() {

        const { title, content, to } = this.props;
        return (
            <div className="list-group-item pt-4 pb-4">
                <Link to={to}>
                    <button className="float-right btn btn-sm btn-outline-secondary mb-2 mt-2">
                        <i className="fa fa-wrench"></i>
                    </button>
                </Link>
                <h6>{title}</h6>
                <p className="mb-0">{content}</p>
            </div>
        );
    }
}

export default AdminItem;