import { sortByProperty, getNestedChildren } from "../scripts/arrayHelper";

const initialState = {
    pages: null,
    pageList: null,
    pageIdToDelete: null,
    openingTimes: null,
    showTimes: false,
}

export default function pageReducer(state = initialState, action) {
    switch(action.type) {
        case 'TOGGLE_TIMES': {
            return {
                ...state,
                showTimes: !state.showTimes
            }
        }
        case 'SET_TIMES': {
            return {
                ...state,
                openingTimes: action.times
            }
        }
        case 'SET_DELETE_PAGE': {
            return {
                ...state,
                pageIdToDelete: action.pageId
            }
        }
        case 'SET_PAGE_LIST': {
            
            return {
                ...state,
                pageList: sortByProperty('pageOrder', getNestedChildren(action.pageList, null, 'parentPageId'))
            }
        }
        case 'SET_PAGES': {
            return {
                ...state,
                pages: sortByProperty('pageOrder', action.pages)
            }
        }
        case 'SET_SIGNUP_TO_DELETE': {
            return {
                ...state,
                signupToDelete: action.id
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}