import React from 'react';
import { useEffect } from 'react';
import { GET } from '../../scripts/api';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { generate24HoursArray } from '../../scripts/timeHelper';

const TimesModal = ({ openingTimes, showTimes, dispatch }) => {

	const getOpeningTimes = () => {
		GET('times/opening-times', {},
			openingTimes => dispatch({
				type: 'SET_TIMES',
				times: openingTimes
			}),
			error => console.log(error)
		);
	}

	const close = () => {
		dispatch({
			type: 'TOGGLE_TIMES'
		})
	}

	useEffect(() => {
		getOpeningTimes()
	}, [])

	const getNonDefaultDays = () => {
		if(openingTimes && openingTimes.length) {
			const nonDefaultDays = openingTimes.filter(time => !time.defaultDay)
			if(nonDefaultDays.length) {
				return nonDefaultDays
			}
		}
		return null
	}

	const times = getNonDefaultDays()

	const timeArr = generate24HoursArray()

	console.log(times)

	return (
		<Modal show={showTimes} onHide={() => close()}>
			<Modal.Body>
				<i onClick={() => close()} className="fa fa-times float-right"></i>
				{!times && <div className="text-center py-3">
					<br/>
					Er zijn op dit moment geen extra openingstijden
				</div>}
				{!!times && <>
					<strong className="text-center">Extra openingstijden</strong>
					<hr />
					{times.map(time => {
						return (
							<div key={time.id}>
								<li className="list-group-item">
									<span className="small">
										<strong>{time.day}</strong>
										<br/>
										<span>{moment(time.date).format("dddd, D MMMM YYYY")}</span>
									</span>
									{!!time.isClosed && <span className="float-right small">(Gesloten)</span>}
									{!time.isClosed && <span className="float-right small">
										Geopend van {timeArr[time.from]} tot {timeArr[time.to]}
									</span>}
								</li>
								
							</div>
						)
					})}
				</>}
			</Modal.Body>
		</Modal>
	);
};

export default connect(
	state => {
		return {
			showTimes: state.pageReducer.showTimes,
			openingTimes: state.pageReducer.openingTimes
		}
	}
)(TimesModal);