import axios from 'axios'
import { getFromLocalStorage } from './localStorage';
import { getEnvVariables } from './environmentVariables';

const apiUrl = getEnvVariables().apiUrl; 
const tokenName = 'CompuToken';
const reconnectTime = 10000 //ms

function request(props, onSuccess, onFail, attemptNumber = 1) {

    const { params, method, url } = props;

    const config = {
        method,
        params,
        url,
        crossDomain: true,
    }
    
    config.headers = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
    }

    const token = getFromLocalStorage(tokenName);

    if(token) {
        config.headers['Authorization'] = 'Bearer ' + token
    }

    axios(config)
    .then(response => {
        if(response.data && response.data.error) {
            console.error(response.data.error)
            onFail(response.data.error)
            setTimeout(() => request(props, onSuccess, onFail), reconnectTime)
        } else {
            // console.log(JSON.stringify(response.data, null, 4));
            onSuccess(response.data)
        }
    })
    .catch(error => {
        console.error(error)
        onFail(error)
        // setTimeout(() => request(props, onSuccess, onFail, attemptNumber + 1), reconnectTime * attemptNumber)
    })
}

export function POST2(route, params, onSuccess, onFail) {

    let headers = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
    }
    const token = getFromLocalStorage(tokenName);

    if(token) {
        headers['Authorization'] = 'Bearer ' + token
    }

    fetch(apiUrl + '/' + route,
        {
            method: 'POST',
            mode: 'cors', 
            headers,
            referrer: 'no-referrer',
            body: JSON.stringify(params),
    }).then(res => res.json())
    .then(result => onSuccess(result))
    .catch(error => onFail(error))
}

export function POST(route, params, onSuccess, onFail) {
    request({
        method: 'POST',
        params,
        url: apiUrl + '/' + route
    }, onSuccess, onFail)
}

export function GET(route, params, onSuccess, onFail) {
    request({
        method: 'GET',
        params,
        url: apiUrl + '/' + route
    }, onSuccess, onFail)
}

export function PUT(route, params, onSuccess, onFail) {
    request({
        method: 'PUT',
        params,
        url: apiUrl + '/' + route
    }, onSuccess, onFail)
}

export function DELETE(route, params, onSuccess, onFail) {
    request({
        method: 'DELETE',
        params,
        url: apiUrl + '/' + route
    }, onSuccess, onFail)
}
