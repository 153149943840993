import React from 'react';

export function isAdmin(user) {
    return user && user.roleName === "admin"
}

export function formatRole(user) {
    if(isAdmin(user)) {
        return (<span className="badge badge-pill badge-danger user-icon">
            <i className="fa fa-user-shield"></i> Administrator
        </span>)
    }
    return (<span className="badge badge-pill badge-primary user-icon">
        <i className="fa fa-user"></i> Gebruiker
    </span>)
}