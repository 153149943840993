import React, { Component } from 'react';

class BanInput extends Component {
    render() {
        const { isBanned, setBanned } = this.props;

        return (
            <>
                <label className="small mt-3"><strong>Deactiveer gebruiker</strong></label>
                <div className="btn-group btn-group-sm w-100">
                    <button onClick={() => setBanned(false)} type="button" className={"w-50 btn btn-sm btn-success" + (!isBanned ? " active" : "")}>
                        <i className="fa fa-user-check"></i> Actief {!isBanned ? <i className="ml-1 fa fa-check text-success"></i> : ""}
                    </button>
                    <button onClick={() => setBanned(true)} type="button" className={"w-50 btn btn-sm btn-danger" + (isBanned ? " active" : "")}>
                        <i className="fa fa-user-slash"></i> Banned {isBanned ? <i className="ml-1 fa fa-check text-success"></i> : ""}
                    </button>
                </div>
            </>
        );
    }
}

export default BanInput;