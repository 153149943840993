import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import { GET } from '../../scripts/api';
import { connect } from 'react-redux';
import './Filter.css';
import { sortNamesAlphabetically } from '../../scripts/sortTickets';

class Filter extends Component {

    state = {
        users: null,
        statusList: null,
        adminUsers: null,
        error: null,

    }

    removeSelectedPropertyFromList = (itemList, property) => {
        const { filterOptions } = this.props;
        const selectedItems = filterOptions[property]
        return itemList.filter(item => {
            const selectedItemIds = selectedItems.map(item => item.id);
            return !selectedItemIds.includes(item.id);
        });
    }

    // removeSelectedAssignedUserFrom = userList => {
    //     const { filterOptions } = this.props;
    //     const selectedItems = filterOptions.assigned
    //     return userList.filter(item => {
    //         const selectedItemIds = selectedItems.map(item => item.id);
    //         return !selectedItemIds.includes(item.id);
    //     });
    // }

    // removeSelectedStatusFrom = statusList => {
    //     const { filterOptions } = this.props;
    //     const selectedItems = filterOptions.status
    //     return statusList.filter(item => {
    //         const selectedItemIds = selectedItems.map(item => item.id);
    //         return !selectedItemIds.includes(item.id);
    //     });
    // }

    // removeSelectedAuthorFrom = userList => {
    //     const { filterOptions } = this.props;
    //     const selectedItems = filterOptions.author
    //     return userList.filter(user => {
    //         const selectedItemIds = selectedItems.map(item => item.id);
    //         return !selectedItemIds.includes(user.id);
    //     });
    // }

    // removeSelectedEditorFrom = userList => {
    //     const { filterOptions } = this.props;
    //     const selectedItems = filterOptions.editor
    //     return userList.filter(user => {
    //         return !selectedItems.includes(user.id);
    //     });
    // }

    removeFilterOption (value, property) {
        let filterOptions = { ...this.props.filterOptions }
        filterOptions[property].splice(filterOptions[property].indexOf(value), 1);
        this.props.dispatch({
            type: 'SET_FILTER_OPTIONS',
            filterOptions
        })
    }

    // selectAllItems = (property, itemList) => {
    //     let filterOptions = { ...this.props.filterOptions } 
    //     filterOptions[property] = [];
    //     itemList.forEach(item => {
    //         filterOptions[property].push(item.name);
    //     })
    //     console.log(filterOptions[property])
    //     this.props.dispatch({
    //         type: 'SET_FILTER_OPTIONS',
    //         filterOptions
    //     })
    // }
    matchPropertyToObject = (value, itemList) => {
        return itemList.find(item => {
            return item.id === parseInt(value, 10);
        })
    }

    setFilter = (e, property, itemList) => {
        const itemToFilter = this.matchPropertyToObject(e.target.value, itemList);

        this.props.dispatch({
            type: 'SET_FILTER_OPTIONS',
            filterOptions: {
                ...this.props.filterOptions,
                [property]: [
                    ...this.props.filterOptions[property],
                    itemToFilter
                ]
            }
        })
    }

    componentDidMount() {
        GET('filter/options',
            {},
            result => this.setState({
                users: result.users,
                statusList: result.statusList,
                adminUsers: result.adminUsers ? result.adminUsers : null
            }),
            error => this.setState({error: true})
        );
    }

    render() {

        const { filtersToggled, filterOptions } = this.props;
        const { users, statusList, adminUsers } = this.state;

        return (
            <Collapse in={filtersToggled} >
                <div className="row small">
                    {statusList && 
                        <div className="col-sm-6 col-md-3 pt-3 pr-3">
                            <label className="mb-2 mr-2">
                                <strong>Status</strong>
                            </label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <label className="input-group-text"><i className="fa fa-tools"></i></label>
                                </div>
                                <select onChange={value => this.setFilter(value, 'status', statusList)} value="" className="form-control form-control-sm">
                                    <option value="">Selecteer</option>
                                    {this.removeSelectedPropertyFromList(statusList, 'status').map(status => {
                                        return <option value={status.id} key={status.id}>{status.name} </option>
                                    })}
                                </select>
                                {/* <div className="input-group-append" onClick={() => this.selectAllItems('status', statusList)}>
                                    <label className="input-group-text"><i className="fa fa-tasks"></i></label>
                                </div> */}
                            </div>
                        </div>}
                    {users && <>
                        <div className="col-sm-6 col-md-3 pt-3 pr-3">
                            <label className="mb-2 mr-2">
                                <strong>Gemaakt door</strong>
                            </label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <label className="input-group-text"><i className="fa fa-user-plus"></i></label>
                                </div>
                                <select onChange={value => this.setFilter(value, 'author', users)} value="" className="form-control form-control-sm">
                                    <option value="">Selecteer</option>
                                    {sortNamesAlphabetically(this.removeSelectedPropertyFromList(users, 'author')).map(user => {
                                        return <option value={user.id} key={user.id}>{user.name}</option>
                                    })}
                                </select>
                                {/* <div className="input-group-append" onClick={() => this.selectAllItems('author', users)}>
                                    <label className="input-group-text"><i className="fa fa-tasks"></i></label>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 pt-3 pl-3">
                            <label className="mb-2 mr-2">
                                <strong>Gewijzigd door</strong>
                            </label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <label className="input-group-text"><i className="fa fa-user-edit"></i></label>
                                </div>
                                <select onChange={value => this.setFilter(value, 'editor', users)} value="" className="form-control form-control-sm">
                                    <option value="">Selecteer</option>
                                    {sortNamesAlphabetically(this.removeSelectedPropertyFromList(users, 'editor')).map(user => {
                                        return <option value={user.id} key={user.id}>{user.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 pt-3 pl-3">
                            <label className="mb-2 mr-2">
                                <strong>Toegewezen aan</strong>
                            </label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <label className="input-group-text"><i className="fa fa-user-tag"></i></label>
                                </div>
                                <select onChange={value => this.setFilter(value, 'assigned', adminUsers)} value="" className="form-control form-control-sm">
                                    <option value="">Selecteer</option>
                                    {sortNamesAlphabetically(this.removeSelectedPropertyFromList(adminUsers, 'assigned')).map(user => {
                                        return <option value={user.id} key={user.id}>{user.name}</option>
                                    })}
                                </select>
                                {/* <div className="input-group-append" onClick={() => this.selectAllItems('editor', users)}>
                                    <label className="input-group-text"><i className="fa fa-tasks"></i></label>
                                </div> */}
                            </div>
                        </div>
                    </>}
                    
                    {!statusList && <>
                        <div className="text-center col-sm-12 pt-5 pb-2">
                            <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                        </div>
                    </>}
                    <div className="col-sm-12 mt-3">
                        {filterOptions && filterOptions.editor.map(editor => {
                            return <span key={editor.id} onClick={() => this.removeFilterOption(editor, 'editor')} className="font-weight-normal filter-item badge badge-pill badge-warning"><i className="fa fa-user-edit"></i> {editor.name}</span>
                        })}
                        {filterOptions && filterOptions.author.map(author => {
                            return <span key={author.id} onClick={() => this.removeFilterOption(author, 'author')} className="font-weight-normal filter-item badge badge-pill badge-info"><i className="fa fa-user-plus"></i> {author.name}</span>
                        })}
                        {filterOptions && filterOptions.status.map(status => {
                            return <span key={status.id} onClick={() => this.removeFilterOption(status, 'status')} className="font-weight-normal filter-item badge badge-pill badge-primary"><i className="fa fa-cog"></i> {status.name}</span>
                        })}
                        {filterOptions && filterOptions.assigned.map(assigned => {
                            return <span key={assigned.id} onClick={() => this.removeFilterOption(assigned, 'assigned')} className="font-weight-normal filter-item badge badge-pill badge-danger"><i className="fa fa-user-shield"></i> {assigned.name}</span>
                        })}
                    </div>
                </div>
            </Collapse>
        );
    }
}

export default connect(
    state => {
        return {
            searchQuery: state.filterReducer.searchQuery,
            currentSortOption: state.filterReducer.currentSortOption,
            currentSortDirection: state.filterReducer.currentSortDirection,
            filtersToggled: state.filterReducer.filtersToggled,
            filterOptions: state.filterReducer.filterOptions,
        }
    }
)(Filter);