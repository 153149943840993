import React from 'react';
import Tooltip from '../../tooltip/Tooltip';
import { generate24HoursArray } from '../../../scripts/timeHelper';
import { formatDate } from '../../../scripts/dateFormatter';
import moment from 'moment';

const OpeningTimeRow = ({ time, edit, remove }) => {

	const timeArr = generate24HoursArray();

	const  { id, day, defaultDay, date, from, to, isClosed } = time

	return (
		<li className="list-group-item d-flex justify-content-between align-items-center">
			<small><strong>{day}</strong> {!!defaultDay && "(Standaard)"}</small>
			<div>
				{(!defaultDay && date) && <span>
					<Tooltip text={formatDate(date)}>
						{moment(date).format("dddd, D MMMM YYYY")}
					</Tooltip>
				</span>}
				{!!isClosed && <span className="mx-2">
					<Tooltip text="Gesloten">
						<i className="fa fa-ban"></i>
					</Tooltip>
				</span>}
				{!isClosed && <>
					<span className="mr-2 badge badge-primary badge-pill">
						Van: {timeArr[from]}
					</span>
					<span className="badge badge-primary badge-pill">
						Tot: {timeArr[to]}
					</span>
				</>}
				<span className="pl-3">
					<Tooltip text="Bewerken">
						<button onClick={() => edit(id)} className="btn btn-outline-warning btn-sm">
							<i className="fa fa-edit"></i>
						</button>
					</Tooltip>
				</span>
				{!defaultDay && <span className="pl-3">
					<Tooltip text="Verwijderen">
						<button onClick={() => remove(id)} className="btn btn-outline-danger btn-sm">
							<i className="fa fa-trash"></i>
						</button>
					</Tooltip>
				</span>}
			</div>
		</li>
	);
};

export default OpeningTimeRow;