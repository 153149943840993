
const initialState = {
    loginOpen: false,
    registerOpen: false,
    currentUser: null,
    unreadMessages: [],
    authLoading: true
}

export default function authReducer(state = initialState, action) {
    switch(action.type) {
        case 'TOGGLE_LOGIN': {
            return {
                ...state,
                loginOpen: !state.loginOpen,
            }
        }
        case 'TOGGLE_REGISTER': {
            return {
                ...state,
                registerOpen: !state.registerOpen,
            }
        }
        case 'DISABLE_AUTH_LOADING': {
            return {
                ...state,
                authLoading: false
            }
        }
        case 'SET_CURRENT_USER': {
            return {
                ...state,
                currentUser: action.user
            }
        }
        case 'SET_UNREAD_MESSAGES': {
            return {
                ...state,
                unreadMessages: action.messages
            }
        }
        case 'SET_CURRENT_PAGE_ID': {
            return {
                ...state,
                pageId: action.pageId
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}