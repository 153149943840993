import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { POST } from '../../scripts/api';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isAdmin } from '../../scripts/roleHelper';

class MessageModal extends Component {

    state = {
        title: '',
        content: '',
        newStatus: null,
        contentError: null,
        adminOnly: false
    }

    setTitle = e => {
        this.setState({title: e.target.value})
    }

    setContent = e => {
        this.setState({content: e.target.value})
    }

    attemptPostMessage = () => {
        const { content } = this.state;

        let contentError = null;
        if(content.length < 3) {
            contentError = 'Voer minimaal één woord in'
        }
        this.setState({contentError})

        if(!contentError) {
            this.postMessage()
        }
    }

    updateStatus = e => {
        if(e.target.value.length) {
            this.setState({newStatus: e.target.value})
        }
    }

    postMessage = () => {
        const { title, content, newStatus, adminOnly } = this.state;
        const { replyId } = this.props;

        let params = {
            title,
            content,
            ticketId: this.props.match.params.id,
            adminOnly,
            replyId
        }

        if(newStatus) {
            params.newStatusId = newStatus
        }

        POST('ticket/message',
            params,
            newDetails => {
                this.props.setDetails(newDetails)
                this.setState({
                    title: '',
                    content: '',
                    newStatus: null,
                    adminOnly: false
                })
                this.props.close()
            },
            error => console.error(error)
        )
    }

    setAdminOnly = e => {
        this.setState({adminOnly: e.target.checked})
    }
    render() {

        const { show, close, currentUser, statusList } = this.props;
        const { title, content, contentError, adminOnly } = this.state;

        return (
            <Modal show={show} onHide={close}>
                <Modal.Header>
                    <Modal.Title>Nieuw Bericht</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="small">Titel</label>
                    <div className="input-group input-group-sm mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-pen mr-2 ml-2"></i></span>
                        </div>
                        <input onChange={this.setTitle} value={title} placeholder={'Voer titel in (Optioneel)'} type="text" className="form-control" />
                    </div>
                    <label className="small">Inhoud</label>
                    <div className="input-group input-group-sm mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-comment mr-2 ml-2"></i></span>
                        </div>
                        <textarea value={content} onChange={this.setContent} placeholder={'Voer bericht in'} rows={5} className={"form-control" + (!!contentError ? (content.length >= 3 ? " is-valid" : " is-invalid") : "")}>
                        </textarea>
                        {contentError && <div className="invalid-feedback">
                            {contentError}
                        </div>}
                    </div>
                    {isAdmin(currentUser) && statusList && <>
                        
                        <label className="small">Nieuwe Status</label>
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <label className="input-group-text"><i className="fa fa-cogs"></i></label>
                            </div>
                            <select onChange={this.updateStatus} className="custom-select" >
                                <option value={''}>Geen</option>
                                {statusList.map(status => {
                                    return (
                                        <option key={status.id} value={status.id}>{status.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input checked={adminOnly} onChange={this.setAdminOnly} type="checkbox" className="custom-control-input" id="adminOnly" />
                            <label className="custom-control-label small" htmlFor="adminOnly">Niet zichtbaar voor gebruikers</label>
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.attemptPostMessage} className="btn btn-sm btn-outline-success"><i className="fa fa-envelope"></i> Plaats Bericht</button>
                    <button onClick={close} className="btn btn-sm btn-outline-secondary"><i className="fa fa-times"></i> Sluiten</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            currentUser: state.authReducer.currentUser
        }
    }
) (MessageModal));