import React, { Component } from 'react';
import { POST } from '../../scripts/api';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


class AssignUser extends Component {

    assignToUser = e => {
        POST('ticket/assign-user',
            { 
                userId: e.target.value,
                ticketId: this.props.match.params.id
            },
            response => this.props.setDetails(response),
            error => console.error(error)
        );
    }

    unAssignUser = userId => {
        POST('ticket/unassign-user',
            { 
                userId,
                ticketId: this.props.match.params.id
            },
            response => this.props.setDetails(response),
            error => console.error(error)
        );
    }

    render() {

        const { users, assignedUsers, currentUser } = this.props;

        return (
            <>
            <div className="row">
                <div className="col-sm-6">
                    <label className="small"><strong>Ticket toewijzen</strong></label>
                    <div className="input-group input-group-sm mb-3 mt-2">
                        <div className="input-group-prepend">
                            <label className="input-group-text"><i className="fa fa-link"></i></label>
                        </div>
                        <select onChange={this.assignToUser} className="custom-select" >
                            <option value={''}>Geen</option>
                            {users.map(user => {
                                return (
                                    <option key={user.id} value={user.id}>{user.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-sm-6">
                <label className="small"><strong>Ticket toegewezen aan</strong></label>
                <br />
                {assignedUsers && assignedUsers.map(assignment => {
                    return (
                        <span key={assignment.user.id} className="small">
                        {currentUser && (assignment.user.id !== currentUser.id) && <span onClick={() => this.unAssignUser(assignment.user.id)} key={assignment.user.id} className="badge badge-pill badge-danger weight-normal filter-item">
                            <i className="fa fa-user-shield"></i> {assignment.user.name}
                        </span>}
                        {currentUser && (assignment.user.id === currentUser.id) && <span onClick={() => this.unAssignUser(assignment.user.id)} key={assignment.user.id} className="badge badge-pill badge-success weight-normal filter-item">
                            <i className="fa fa-user"></i> {assignment.user.name} (Mij)
                        </span>}
                        </span>
                    )
                })}
                {assignedUsers && !assignedUsers.length && <>
                    <div className="text-center small pt-3 pb-3">
                        Geen toegewezen gebruikers
                    </div>
                </>}
                </div>
            </div>
            <hr/>
            </>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            currentUser: state.authReducer.currentUser
        }
    }
) (AssignUser));