import React, { Component } from 'react';
import { GET } from '../../../scripts/api';
import TicketUserButton from '../../buttons/TicketUserButton';
import DateButton from '../../buttons/DateButton';
import { formatRole } from '../../../scripts/roleHelper';
import { connect } from 'react-redux';
import UserEditModal from './UserEditModal';
import Pagination from '../../table/Pagination';
import TableWrapper from '../../table/TableWrapper';
import Tooltip from '../../tooltip/Tooltip';
import PrevPageButton from '../../buttons/PrevPageButton';

class AdminUsers extends Component {

    state = {
        currentPage: 1,
        pageSize: 10,
        searchQuery: '',
        sortProperty: 'id'
    }

    componentDidMount() {
        this.getAdminUserList();
    }

    getAdminUserList = () => {
        GET('admin/user-list',
            {},
            userList => this.props.dispatch({
                type: 'SET_USER_LIST',
                userList
            }),
            error => console.error(error),
        );
    }

    setUserToBan = userId => {
        this.props.dispatch({
            type: 'SET_USER_TO_BAN',
            userId
        })
    }

    setUserToEdit = userId => {
        this.props.dispatch({
            type: 'SET_USER_TO_EDIT',
            userId
        })
    }

    setPage = pageNumber => {
        this.setState({currentPage: pageNumber})
    }

    setPageSize = e => {
        this.setState({pageSize: e.target.value})
    }

    filterPage = userList => {
        const { currentPage, pageSize } = this.state;
        return userList.slice((currentPage -1) * pageSize, Math.min(userList.length, (currentPage * pageSize)));
    }

    filterBySearch = userList => {
        const { searchQuery } = this.state;
        return userList.filter(user => {
            const search = searchQuery.toLowerCase()
            return  user.email.toLowerCase().indexOf(search) !== -1 ||
                user.name.toLowerCase().indexOf(search) !== -1 ||
                user.roleName.toLowerCase().indexOf(search) !== -1
        })
    }

    applyFilterOptions = userList => {
        userList = this.filterBySearch(userList);
        return userList;
    }

    setSearchQuery = e => {
        this.setState({searchQuery: e.target.value})
    }

    clearSearchQuery = () => {
        this.setState({searchQuery: ''})
    }

    render() {

        const { userList } = this.props
        const { currentPage, pageSize, searchQuery } = this.state

        const filteredUsers = userList ? this.applyFilterOptions(userList): []
        const currentPageUsers = filteredUsers ? this.filterPage(filteredUsers): []


        return (
            <div className="content">
                <div className="container-fluid">
                    {userList && <>
                        <h3><i className="fa fa-users"></i> Gebruikersoverzicht ({filteredUsers.length}) <PrevPageButton to={'/admin'} /></h3>
                        <hr />
                        <div className="row">
                            <div className="col-sm-6 pr-1 pt-2 pb-2 pr-3">
                                <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text pl-3 pr-3"><i className="fa fa-search"></i></span>
                                    </div>
                                    <input onChange={this.setSearchQuery} value={searchQuery} type="text" className="form-control" placeholder="Doorzoek gebruikers" />
                                    <div onClick={this.clearSearchQuery} className="input-group-append button-icon">
                                        <span className="input-group-text pl-3 pr-3"><i className="fa fa-times"></i></span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        {filteredUsers && !!filteredUsers.length && <>
                            <TableWrapper>
                                <thead className="thead-light text-center">
                                    <tr style={{width: '100%'}}>
                                        <th style={{minWidth: '50px'}} scope="col">
                                            ID 
                                            {/* <SortButton onSort={this.sortByProp} sortOption={sortProps.ID} currentSortOption={currentSortOption} /> */}
                                        </th>
                                        <th style={{minWidth: '200px'}} scope="col">
                                            Naam 
                                            {/* <SortButton onSort={this.sortByProp} sortOption={sortProps.ID} currentSortOption={currentSortOption} /> */}
                                        </th>
                                        <th style={{minWidth: '200px'}} scope="col">
                                            Geregistreerd op 
                                            {/* <SortButton onSort={this.sortByProp} sortOption={sortProps.TITLE} currentSortOption={currentSortOption} /> */}
                                        </th>
                                        <th style={{minWidth: '200px'}} scope="col">
                                            Gewijzigd op 
                                            {/* <SortButton onSort={this.sortByProp} sortOption={sortProps.TITLE} currentSortOption={currentSortOption} /> */}
                                        </th>
                                        <th style={{minWidth: '150px'}} scope="col">
                                            Rechten 
                                            {/* <SortButton onSort={this.sortByProp} sortOption={sortProps.TITLE} currentSortOption={currentSortOption} /> */}
                                        </th>
                                        <th style={{minWidth: '150px'}} scope="col">
                                            E-mailadres 
                                            {/* <SortButton onSort={this.sortByProp} sortOption={sortProps.TITLE} currentSortOption={currentSortOption} /> */}
                                        </th>
                                        <th style={{minWidth: '150px'}} scope="col">
                                            Wijzigen 
                                            {/* <SortButton onSort={this.sortByProp} sortOption={sortProps.TITLE} currentSortOption={currentSortOption} /> */}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {currentPageUsers.map(user => {
                                        return (<tr key={user.id} className={!!user.isBanned ? "table-danger" : ""}>
                                            <td><i className="fa fa-id-card"></i> {user.id}</td>
                                            <td>
                                                <TicketUserButton user={user} />
                                            </td>
                                            <td><DateButton date={user.created_at} /></td>
                                            <td><DateButton date={user.updated_at} /></td>
                                            <td>
                                                {!!user.isBanned && <Tooltip text={"Gebruiker is inactief"}>
                                                    <button className="disabled btn btn-sm btn-outline-danger mr-3"><i className="fa fa-ban"></i></button>
                                                </Tooltip>}
                                                {!user.isBanned && formatRole(user)}
                                            </td>
                                            <td>
                                                {user.email}
                                            </td>
                                            <td>
                                                <button onClick={() => this.setUserToEdit(user.id)} className="btn mr-2 btn-sm btn-outline-warning">
                                                    <i className="fa fa-user-edit"></i>
                                                </button>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </TableWrapper>
                            <Pagination 
                                itemCount={filteredUsers.length}
                                pageSize={pageSize} 
                                currentPage={currentPage}
                                setPage={this.setPage}
                                setPageSize={this.setPageSize}
                            />
                        </>}
                        {!filteredUsers.length && <>
                            <hr className="pt-4" />
                            <div className="empty-results text-secondary small">
                                <i className="fa fa-folder-open"></i> Geen tickets gevonden.
                            </div>
                            <hr className="mt-5"/>
                        </>}
                        {!userList && <div className="loading-container">
                            <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                        </div>}
                    </>}
                </div>
                <UserEditModal />
            </div>
        );
    }
}

export default connect(
    state => {
        return {
            userList: state.userReducer.userList
        }
    }
)(AdminUsers);