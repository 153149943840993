import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { POST } from '../../../scripts/api';
import IconPicker from '../../icons/IconPicker';
import ColorPicker from '../../color/ColorPicker';
import { isValidHexColor } from '../../../scripts/colorHelper';
import { iconList } from '../../icons/iconList';
import { hasErrors } from '../../../scripts/errorHelper';

class CreateStatusModal extends Component {

    state = {
        name: '',
        description: '',
        symbol: '',
        color: '#000000',
        errors: {
            name: null,
            description: null,
            symbol: null,
            color: null,
        }
    }

    close = () => {
        this.setState({
            name: '',
            description: '',
            color: '#000000',
            symbol: ''
        })
        this.props.dispatch({
            type: 'STOP_CREATE_NEW_STATUS'
        })
    }

    set = (e, property) => this.state.hasOwnProperty(property) && this.setState({[property]: e.target.value})

    attemptCreateStatus = () => {
        if(this.validateNewStatus()) {
            this.createStatus();
        }
    }

    validateNewStatus = () => {
        const { name, description, symbol, color } = this.state;
        let errors = { ...this.state.errors }

        errors.description = description.length < 3 ? "Voer een omschrijving in" : null;
        errors.name = name.length < 3 ? "Voer een naam in" : null;
        errors.symbol = (symbol.length && iconList().indexOf(symbol) === -1) ? "Dit is geen geldig symbool" : null
        errors.color = !isValidHexColor(color) ? 'Dit is geen geldige kleur' : null;
        this.setState({errors})
        return !hasErrors(errors);

    }

    createStatus = () => {
        const { name, description, symbol, color } = this.state;

        POST('ticket-status/create', 
            {
                name,
                color,
                description,
                symbol
            },
            statusList => {
                this.props.dispatch({
                    type: 'SET_STATUS_LIST',
                    statusList
                })
                this.props.dispatch({
                    type: 'RESET_CREATE_STATUS'
                })
                this.close()
            },
            error => console.error(error)
        );
    }

    render() {

        const { createNewStatus } = this.props;
        const { errors, name, description, symbol, color } = this.state;

        return (
            <Modal show={!!createNewStatus} onHide={this.close}>
                <Modal.Header>
                    <Modal.Title>Nieuwe status toevoegen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="small"><strong>Status Naam</strong></label>
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-bars mr-2 ml-2"></i></span>
                        </div>
                        <input 
                            value={name} 
                            onChange={e => this.set(e, 'name')} 
                            type="text" 
                            className={"form-control" + (errors.name ? (name.length >= 3 ? " is-valid" : " is-invalid") : "")}
                            id="ticket_title" 
                            placeholder="Voer een naam in voor de status" 
                        />
                        {errors.name && <div className="invalid-feedback">
                            {errors.name}
                        </div>}
                    </div>

                    <label className="small mt-3"><strong>Status Omschrijving</strong></label>
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-bars mr-2 ml-2"></i></span>
                        </div>
                        <textarea value={description} onChange={e => this.set(e, 'description')} placeholder={'Voer omschrijving in'} rows={5} className={"form-control" + (errors.description ? (description.length >= 3 ? " is-valid" : " is-invalid") : "")}>
                        </textarea>
                        {errors.description && <div className="invalid-feedback">
                            {errors.description}
                        </div>}
                    </div>
                    <IconPicker icon={symbol} setIcon={symbol => this.setState({symbol})} />
                    {errors.symbol && <div className="invalid-feedback d-inline">
                        {errors.symbol}
                    </div>}
                    <ColorPicker color={color} setColor={color => this.setState({color})} />
                    {errors.color && <div className="invalid-feedback d-inline">
                        {errors.color}
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.attemptCreateStatus} className="btn btn-sm btn-outline-success"><i className="fa fa-plus"></i> Status toevoegen</button>
                    <button onClick={this.close} className="btn btn-sm btn-outline-secondary"><i className="fa fa-times"></i> Sluiten</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            createNewStatus: state.statusReducer.createNewStatus
        }
    }
) (CreateStatusModal));