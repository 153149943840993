export function sortNamesAlphabetically(userList) {
    return userList.sort((firstUser, secondUser) => {
        if(firstUser.name < secondUser.name) { return -1; }
        if(firstUser.name > secondUser.name) { return 1; }
        return 0;
    })
}

export function sortTicketsByProperty(property, direction, tickets) {
    if(direction === 'down') {
        return sortDown(property, tickets)
    }
    return sortUp(property, tickets);
}

function sortDown(property, tickets) {
    return tickets.sort((first, second) => {
        const firstProp = getSortProperty(property, first)
        const secondProp = getSortProperty(property, second)
        return secondProp > firstProp ? 1 : -1
    })
}

function sortUp(property, tickets) {
    return tickets.sort((first, second) => {
        const firstProp = getSortProperty(property, first)
        const secondProp = getSortProperty(property, second)
        return firstProp > secondProp ? 1 : -1
    })
}

function getSortProperty(property, ticket) {

    const sortProps = sortProperties();
    
    if(sortProps.EDITOR === property && ticket.user_last_edited) {
        return ticket.user_last_edited.name
    }
    switch(property) {
        case sortProps.ID:
            return ticket.id
        case sortProps.TITLE:
            return ticket.title
        case sortProps.DESC:
            return ticket.content
        case sortProps.AUTHOR:
            return ticket.user_created ? ticket.user_created.name : ''
        case sortProps.EDITOR:
            return ticket.user_last_edited ? ticket.user_last_edited.name : ''
        case sortProps.CREATED_DATE:
            return new Date(ticket.createdAt)
        case sortProps.UPDATED_DATE:
            return new Date(ticket.updatedAt)
        case sortProps.STATUS:
            return ticket.ticket_status.name
        default: {
            return ''
        }
    }
}

export function sortProperties() {
    return {
        ID: 'id',
        TITLE: 'title',
        DESC: 'desc',
        AUTHOR: 'author',
        EDITOR: 'editor',
        STATUS: 'status',
        CREATED_DATE: 'createdAt',
        UPDATED_DATE: 'updatedAt'
    }
}