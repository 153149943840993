import { sortProperties } from '../scripts/sortTickets';

const initialState = {
    searchQuery: '',
    currentSortOption: sortProperties().AUTHOR,
    currentSortDirection: 'down',
    filtersToggled: false,
    filterOptions: {
        author: [],
        editor: [],
        status: [],
        assigned: []
    },
}

export default function filterReducer(state = initialState, action) {
    switch(action.type) {
        case 'SET_SEARCH_QUERY': {
            return {
                ...state,
                searchQuery: action.searchQuery
            }
        }
        case 'SET_SORT_OPTION': {
            return {
                ...state,
                currentSortOption: action.sortOption
            }
        }
        case 'SET_SORT_DIRECTION': {
            return {
                ...state,
                currentSortDirection: action.sortDirection
            }
        }
        case 'TOGGLE_FILTER': {
            return {
                ...state,
                filtersToggled: !state.filtersToggled
            }
        }
        case 'SET_FILTER_OPTIONS': {
            return {
                ...state,
                filterOptions: action.filterOptions
            }
        }
        case 'CLEAR_FILTER_OPTIONS': {
            return {
                ...state,
                filterOptions: initialState.filterOptions
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}