import React, { Component } from 'react';
import { GET } from '../../../scripts/api';
import StatusItem from './StatusItem';
import DeleteStatusModal from './DeleteStatusModal';
import { connect } from 'react-redux'
import CreateStatusModal from './CreateStatusModal';
import EditStatusModal from './EditStatusModal';
import { Link } from 'react-router-dom'

class AdminStatusses extends Component {

    componentDidMount() {
        this.getStatusList();
    }

    getStatusList = () => {
        GET('status-list',
            {},
            statusList => this.props.dispatch({
                type: 'SET_STATUS_LIST',
                statusList
            }),
            error => console.error(error)
        );
    }

    createNewStatus = () => {
        this.props.dispatch({
            type: 'CREATE_NEW_STATUS'
        })
    }

    render() {

        const { statusList } = this.props;

        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <h3>
                                <i className="fa fa-clipboard-list"></i> Ticket Statussen ({statusList ? statusList.length : 0})
                            </h3>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <button onClick={this.createNewStatus} className="btn btn-sm float-right btn-outline-success my-1"><i className="fa fa-plus"></i> Nieuwe status</button>
                            <Link to={'/admin'} ><button type="button" className="mr-2 float-right btn btn-sm btn-outline-secondary my-1"><i className="fa fa-chevron-left"></i> Terug</button></Link>
                        </div>
                    </div>
               
                <hr />
                {!statusList && <div className="loading-container">
                    <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                </div>}
                {statusList && <>
                    <div className="list-group">
                        {statusList.map((status) => {
                            return <span key={status.id}> 
                                <StatusItem noRemove={statusList.length === 1} status={status} />
                            </span>
                        })}
                    </div>
                    <EditStatusModal />
                    <CreateStatusModal />
                    <DeleteStatusModal />
                </>}
            </div>
            </div>
        );
    }
}

export default connect(
    state => {
        return {
            statusList: state.statusReducer.statusList
        }
    }
)(AdminStatusses);