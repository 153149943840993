import React, { Component } from 'react';
import Tooltip from '../tooltip/Tooltip';

class ReparatieStatusButton extends Component {

    getStyleFromProps = status => {
        return {
            borderColor: status.color,
            color: status.color,
            minWidth: '100px',
        }
    }

    render() {

        const { status, large, preText } = this.props;

        return (<>
            {status && 
                <div className={large ? " large-status" : ""}>
                    {preText && <span>{preText}</span>}
                    <Tooltip text={status.description}>
                        <button style={this.getStyleFromProps(status)} className="disabled btn btn-outline-secondary btn-sm">
                            <span><i className={status.symbol}></i> {status.name}</span>
                        </button>
                    </Tooltip>
                </div>}
                {!status && <div className="text-center small pt-2 pb-2">
                    Geen status
                </div>}
            </>);
    }
}

export default ReparatieStatusButton;