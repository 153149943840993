import React, { Component } from 'react';


class WysiwygContainer extends Component {

    maxContentLength = Math.pow(2,16);

    componentDidMount() {
        window.$('#summernote').summernote({
            placeholder: 'Voer hier de pagina inhoud in',
            tabsize: 2,
            height: 300,
            lang: 'nl-NL',
            fontSizes: ['8', '9', '10', '11', '12', '14', '18', '24', '36', '48' , '64', '82', '150'],
            toolbar: [
                ['style',  ['bold', 'italic', 'underline', 'clear']],
                ['font', ['superscript', 'subscript', 'fontname']],
                ['fontsize', ['fontsize']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['height', ['height']],
                ['insert', ['picture', 'video']],
                ['link', ['linkDialogShow', 'unlink']],
                ['color', ['color']],
                ['table', ['table']],
            ],
            callbacks: {
                onChange: content => {
                    this.props.setContent(content)
                }
            }
        })
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.content && !this.props.content.length) {
            window.$('#summernote').summernote('code', nextProps.content);
        }
        // if(nextProps.content && !!this.props.content.length) {
        //     window.$('#summernote').summernote('code', nextProps.content);
        // }
    }

    render() {

        const { error, content } = this.props;

        return (
            <>
                <div className="pt-3 wysiwig-container">
                    <label className="small"><strong>Pagina inhoud</strong></label>
                    {content && <small className={"float-right " + (content.length > this.maxContentLength ? "text-danger" : "text-success")}>({content.length} / {this.maxContentLength} karakters)</small>}
                    <div id="summernote"></div>
                </div>
            </>
        );
    }
}

export default WysiwygContainer;