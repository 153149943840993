import React, { Component } from 'react';
import { POST } from './../../scripts/api';
import { addToLocalStorage } from './../../scripts/localStorage';
import { isValidEmail } from '../../scripts/formValidator';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { hasErrors } from '../../scripts/errorHelper';
import { Modal } from 'react-bootstrap';
import './Login.css';

class Login extends Component {

    tokenName = 'CompuToken';

    state = {
        loading: false,
        email: '',
        password: '',
        rememberMe: false,
        errors: {
            email: null,
            password: null
        }
    }

    attemptLogin = () => {
        if(this.areLoginFieldsValid()) {
            this.clearErrors();
            this.login();
        }
    }

    clearErrors = () => {
        let errors = { ...this.state.errors }
        Object.keys(errors).forEach(key => errors[key] = null);
        this.setState({ errors });
    }

    areLoginFieldsValid = () => {
        let errors = {}
        const { email, password } = this.state;
        errors.email = !isValidEmail(email) ? 'Voer een geldig email adres in.' : null
        errors.password = password.length < 1 ? 'Voer een wachtwoord in' : null
        this.setState({ errors })
        return !hasErrors(errors);
        
    }

    enableLoading = () => {
        this.setState({
            loading: true
        })
    }

    stopLoading = () => {
        this.setState({
            loading: false
        })
    }

    login = () => {

        const { email, password, rememberMe } = this.state;
        this.enableLoading();

		POST('user/login', {
			email,
			password
		},
		data => {
			this.props.dispatch({
				type: 'SET_CURRENT_USER',
				user: data.user
            })
            addToLocalStorage(this.tokenName, data.token);
            this.close();
            this.props.history.push('/');
            this.stopLoading();

		},
		error => {
            let errors = { ...this.state.errors };

            Object.keys(errors).forEach(key => {
                errors[key] = error[key];    
            })
            this.setState({ errors })
            this.stopLoading();

        })
    }

    set = (property, value) => {
        this.setState({
            errors: { ...this.state.errors, [property]: null},
            [property]: value
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        this.attemptLogin();
    }

    close = () => {
        this.props.dispatch({
            type: 'TOGGLE_LOGIN'
        })
    }

    render() {

        const { email, password, errors, loading, rememberMe } = this.state;
        const { currentUser, loginOpen } = this.props;

        return (
            <Modal centered show={loginOpen && !currentUser} onHide={() => this.close()}>
                <div className="container-fluid">
                    <div className="card-body login-form">
                        <i onClick={() => this.close()} className="fa fa-times float-right"></i>
                        <br />
                        <h5 className="card-title text-center">Inloggen</h5>
                        <h6 className="card-subtitle mb-2 text-muted text-center">CompuCorner Ticketcentrum</h6>
                        <hr />
                        {(loading || currentUser) && <div className="loading-container">
                            <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                        </div>}
                        {(!loading && !currentUser) && <form onSubmit={this.handleSubmit}>
                            <div className="form-group mb-3">
                                <label className="small" htmlFor="login_email"><strong>Email</strong></label>
                                <div className="input-group input-group-sm">
                                    {/* <div className="input-group-prepend">
                                        <span className="input-group-text">@</span>
                                    </div> */}
                                    <input 
                                        onKeyDown={e => e.key === "Enter" && this.attemptLogin()}
                                        value={email} 
                                        onChange={e => this.set('email', e.target.value)} type="email" 
                                        className={"form-control form-control-sm " + (errors.email ? "is-invalid" : (isValidEmail(email) ? "is-valid" : ""))} 
                                        id="login_email" 
                                        placeholder="Voer email adres in" 
                                    />
                                    {errors.email && <div className="invalid-feedback">
                                        {errors.email}
                                    </div>}
                                </div>
                            </div>
                            
                            <div className="form-group mb-4">
                                <label className="small" htmlFor="login_password"><strong>Wachtwoord</strong></label>
                                <div className="input-group input-group-sm">
                                    {/* <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-lock"></i></span>
                                    </div> */}
                                    <input 
                                        onKeyDown={e => e.key === "Enter" && this.attemptLogin()}
                                        value={password} 
                                        type="password" 
                                        onChange={e => this.set('password', e.target.value)} 
                                        className={"form-control form-control-sm " + (errors.password ? "is-invalid" : (password.length >= 6 ? "is-valid" : ""))} 
                                        id="login_password" 
                                        placeholder="Voer wachtwoord in" 
                                    />
                                    {errors.password && <div className="invalid-feedback">
                                        {errors.password}
                                    </div>}
                                </div>
                            </div>
                            <div className="custom-control custom-checkbox mr-sm-2">
                                <input value={rememberMe}  type="checkbox" className="custom-control-input" onChange={e => this.set('rememberMe', e.target.checked)} id="customControlAutosizing" />
                                <label className="custom-control-label" for="customControlAutosizing">Onthoud mij</label>
                            </div>
                            <br />
                            <button onClick={this.attemptLogin} type="button" className="btn btn-primary w-100">Inloggen</button>
                            <br />
                            <br />
                        </form>}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withRouter(connect(
    state => {
        return {
            currentUser: state.authReducer.currentUser,
            loginOpen: state.authReducer.loginOpen,
        }
    }
) (Login));