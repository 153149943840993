import React, { Component } from 'react';

class Pagination extends Component {

    // Props: { itemCount, currentPage, pageSize, setPage, setPageSize }

    state = {
        dummyPageValue: this.props.currentPage
    }

    createPageList = () => {
        const { itemCount, currentPage, pageSize } = this.props;
        const pageCount = itemCount / pageSize;
        const pageList = [];

        for(let x = 0; x < pageCount; x++) {
            const isActive = (x + 1) === currentPage
            pageList.push(
                <li key={x} onClick={() => this.props.setPage(x + 1)}className={"page-item" + (isActive ? " active" : "")}><span className="page-link">{x + 1}</span></li>
            )
        }
        return pageList;
    }

    getPageCount = () => {
        const { itemCount, pageSize } = this.props;
        return Math.ceil(itemCount / pageSize);
    }

    incrementPage = () => {
        const newPageValue = Math.min(this.getPageCount(), this.props.currentPage + 1)
        this.props.setPage(newPageValue)
        this.setState({dummyPageValue: newPageValue})
    }   

    decrementPage = () => {
        const newPageValue = Math.max(1, this.props.currentPage - 1)
        this.props.setPage(newPageValue)
        this.setState({dummyPageValue: newPageValue})

    }

    setPageSize = pageSize => {
        this.props.setPage(1);
        this.props.setPageSize(pageSize)
        this.setState({dummyPageValue: 1})
    }

    goToFirstPage = () => {
        this.props.setPage(1)
        this.setState({dummyPageValue: 1})
    }

    goToLastPage = () => {
        const pageCount = this.getPageCount()
        this.props.setPage(pageCount)
        this.setState({dummyPageValue: pageCount})
    }

    editPage = e => {
        let newValue = e.target.value 
        if(!isNaN(newValue) && newValue !== '') {
            newValue = Math.min(this.getPageCount(), Math.max(1, e.target.value))
            this.props.setPage(newValue)
        }
        this.setState({dummyPageValue: newValue})
    }

    render() {

        const { pageSize } = this.props;
        const { dummyPageValue } = this.state;
        return (
            <div className="row small">
                <div className="col-sm-6">
                    <div className=" input-group input-group-sm mb-3">
                        <div className="input-group-prepend">
                            <label className="input-group-text"><i className="fa fa-sort-numeric-down mr-2"></i> Aantal per pagina</label>
                            
                        </div>
                        <select onChange={value => this.setPageSize(value)} value={pageSize} className="font-weight-bold custom-select page-amount custom-select-sm">
                            <option className="font-weight-bold" value={10}>10</option>
                            <option className="font-weight-bold" value={25}>25</option>
                            <option className="font-weight-bold" value={50}>50</option>
                            <option className="font-weight-bold" value={100}>100</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="btn-group btn-group-sm float-right">
                        {/* <div className="input-group-prepend"> */}
                            <button onClick={this.goToFirstPage} type="button" className="btn btn-sm btn-outline-secondary"><i className="fa fa-fast-backward text-dark"></i></button>
                        {/* </div> */}
                        {/* <div className="input-group-prepend"> */}
                            <button onClick={this.decrementPage} type="button" className="btn btn-sm btn-outline-secondary"><i className="ml-1 mr-1 fa fa-caret-left text-dark"></i></button>
                        {/* </div> */}
                        {/* <div className="input-group-prepend"> */}
                            <button disabled className="btn btn-sm text-dark btn-outline-dark border-right-0">Pagina</button>     
                        {/* </div> */}
                        <input className="text-center font-weight-bold text-secondary" style={{width: '40px'}} onChange={this.editPage} value={dummyPageValue}/> 
                        {/* <div className="input-group-append"> */}
                            <button disabled className="d-flex btn btn-sm text-dark btn-outline-dark"> van <strong className="ml-1"> {this.getPageCount()}</strong></button>     
                        {/* </div>     */}
                        {/* <div className="input-group-append"> */}
                            <button onClick={this.incrementPage} type="button" className="btn btn-sm btn-outline-secondary"><i className="ml-1 mr-1 fa fa-caret-right text-dark"></i></button>
                        {/* </div> */}
                        {/* <div className="input-group-append"> */}
                            <button onClick={this.goToLastPage} type="button" className="btn btn-sm btn-outline-secondary"><i className="fa fa-fast-forward text-dark"></i></button>
                        {/* </div> */}
                    </div>
                </div>
            </div>

        );
    }
}

export default Pagination;