import React, { Component } from 'react';
import { GET } from '../../scripts/api';

class Profile extends Component {

    state = {
        userData: null,
        error: false
    }

    componentDidMount() {
        GET('user/details',
            {},
            userData => this.setState({userData}),
            error => this.setState({error: true})
        );
    }

    getRole = roleName => {
        if(roleName === 'admin') {
            return 'Administrator';
        }
        return 'Gebruiker';
    }

    getRoleButtonClassName = roleName => {
        if(roleName === 'admin') {
            return 'btn btn-sm btn-danger'
        }
        return 'btn btn-sm btn-primary'
    }

    render() {

        const { userData, error } = this.state;

        return (
            <div className="content">
                <div className="container-fluid">
                    {!userData && !error && <div className="loading-container">
                        <div className="spinner-border text-secondary spinner-border-sm" role="status"></div>
                    </div>}

                    {userData && <>
                        <h3 className="text-secondary mb-5"><i className="fa fa-user"></i> Mijn Gegevens</h3>
                        {userData.user && <>
                            <div className="form-group row small">
                                <label className="col-sm-2 col-form-label ">E-mail</label>
                                <div className="col-sm-10">
                                    <div className="input-group input-group-sm mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">@</span>
                                        </div>
                                        <input disabled type="text" className="form-control" value={userData.user.email} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row small">
                                <label className="col-sm-2 col-form-label">Naam</label>
                                <div className="col-sm-10">
                                    <div className="input-group input-group-sm mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fa fa-user"></i></span>
                                        </div>
                                        <input disabled type="text" className="form-control" value={userData.user.name} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row small">
                                <label className="col-sm-2 col-form-label">Gebruikerstype</label>
                                <div className="col-sm-10">
                                    <button disabled className={this.getRoleButtonClassName(userData.user.roleName)}>
                                        <i className="fa fa-user-tag"></i> {this.getRole(userData.user.roleName)}
                                    </button>
                                </div>
                            </div>
                        
                        </>}

                        {/* <h2 className="text-secondary mt-5 mb-5">
                            <i className="fa fa-envelope"></i> Mijn Tickets ({userData.tickets && userData.tickets.length})
                            <Link to={'/tickets'}>
                                <button className="float-right btn btn-outline-primary">
                                    <i className="fa fa-envelope"></i> Naar Overzicht
                                </button>
                            </Link>
                        </h2>
                        {userData.tickets && <ProfileTickets tickets={userData.tickets} />} */}
                    </>}
                    {error && <>
                        <div className="text-center mt-5">
                            <h1 className="text-secondary"><i className="fa fa-exclamation-triangle"></i> Geen toegang</h1>
                            <p>Je hebt geen toegang tot deze pagina.</p>
                        </div>
                    </>}
                </div>
            </div>
        );
    }
}

export default Profile;